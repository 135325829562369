/**
 * Página Detalle Producto:
 *
 * acá se muestra el detalle del producto
 *
 * @module PaginaProductDetailjsx
 */

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Carousel from "react-bootstrap/Carousel";
import ReactGA from "react-ga4";

import "./productdetail.css";

import { MediosPago, Simulator, DevolucionGratis } from "../components";
import { RelacionadosCards } from "../components/";

import { useSelector } from "react-redux";
import htmlReactParser from "html-react-parser";

const searchParams = new URLSearchParams(document.location.search);

const ProductDetail = () => {
  /**
   * @constant
   * @name producto
   * @type {Array}
   *
   * */

  const [producto, setProducto] = useState([]);

  /**
   * @constant
   * @name isLoaded
   * @type {boolean}
   *
   * */

  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @constant
   * @name currentPicture
   * @type {string}
   *
   * */
  const [currentPicture, setCurrentPicture] = useState("foto_1");

  /**
   * @constant
   * @name estadoFlecha
   * @type {number}
   *
   * */
  const [estadoFlecha, setEstadoFlecha] = useState(1);

  /**
   * @constant
   * @name scroll
   * @type {number}
   *
   * */
  const [scroll, setScroll] = useState(1);

  const totalFotos = useRef(0);

  /**
   * @constant
   * @name mostrando
   * @type {number}
   *
   * */
  const [mostrando, setMostrado] = useState(1);
  const slug = useRef("");

  /**
   * @constant
   * @name fotos
   * @type {Array}
   *
   * */
  const [fotos, setFotos] = useState([]);
  const { isActive, nActive } = useState();

  const idCarro = useSelector((state) => state.idCarro);
  const checkStock = useSelector((state) => state.checkStock);
  const [marcaje, setMarcaje] = useState(null);

  const inner_item = useRef();

  /**
   * @function
   * Petición HTTP que trae el detalle del producto
   * @param {string} id Id del producto
   * @param {number} idCarro Id del carro actual del usuario logueado
   *@returns {Array}
   *
   */

  useEffect(() => {
    const pathname2 = window.location.pathname;
    const match2 = pathname2.split("/");
    const id2 = validaQuery(match2[1]) ? match2[1] : "";

    axios
      .get(
        process.env.REACT_APP_BACK_URL +
          `/api/ga/detalleproductoga4?slug=${id2}`
      )
      .then((response) => {
        ReactGA.gtag("event", "view_item", {
          currency: "CLP",
          value: response.data.precio,
          items: [
            {
              item_id: response.data.sku,
              item_name: response.data.nombre,
              item_brand: response.data.marca,
              item_category: response.data.categoria_padre,
              item_category2: response.data.categoria_hija,
              price: response.data.precio,
              quantity: 1,
            },
          ],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const pathname = window.location.pathname;
    const match = pathname.split("/");
    console.log("B: ");
    console.log(match);
    console.log("C: ");
    console.log(match[1]);
    console.log("D: ");
    console.log(validaQuery(match[1]));
    const id = validaQuery(match[1]) ? match[1] : "";
    console.log("E: ");
    console.log(id);
    slug.current = id;

    axios
      .get(process.env.REACT_APP_BACK_URL + `/slug/${id}?carro=${idCarro}`)
      .then((response) => {
        setProducto(response.data?.results);
        setIsLoaded(true);

        setFotos([]);

        let contador = 0;

        if (response.data.results[0].foto_1) {
          setFotos((fotos) => [...fotos, response.data.results[0].foto_1]);

          contador++;
        }

        if (response.data.results[0].foto_2) {
          setFotos((fotos) => [...fotos, response.data.results[0].foto_2]);

          contador++;
        }

        if (response.data.results[0].foto_3) {
          setFotos((fotos) => [...fotos, response.data.results[0].foto_3]);

          contador++;
        }

        if (response.data.results[0].foto_4) {
          setFotos((fotos) => [...fotos, response.data.results[0].foto_4]);

          contador++;
        }

        if (response.data.results[0].foto_5) {
          setFotos((fotos) => [...fotos, response.data.results[0].foto_5]);

          contador++;
        }

        if (response.data.results[0].foto_6) {
          setFotos((fotos) => [...fotos, response.data.results[0].foto_6]);

          contador++;
        }

        totalFotos.current = contador;
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idCarro, checkStock]);

  /**
   * @function
   * Metodo reemplaza caracteres en un string
   * @param {string} texto string para ser validado
   *@returns {string}
   *
   */
  const handleJsonProducto = (texto) => {
    let prod = texto;
    while (prod.includes("<br>")) prod = prod.replace("<br>", " ");

    const regex = /(<([^>]+)>)/gi;
    prod = prod.replace(regex, "");

    while (prod.includes("\n")) prod = prod.replace("\n", " ");

    return prod;
  };

  /**
   * @function
   * Metodo para cambiar la flecha de los accordiones de bootstrap
   *
   */
  const cambiaFlecha = () => {
    let flecha = document.querySelector("#boton-acordeon");
    let collapse = document.querySelector("#collapseOne");
    if (estadoFlecha == 1) {
      flecha.classList.remove("fa-arrow-circle-down");
      flecha.classList.add("fa-arrow-circle-up");
      setEstadoFlecha(2);
    } else {
      flecha.classList.remove("fa-arrow-circle-up");
      flecha.classList.add("fa-arrow-circle-down");
      setEstadoFlecha(1);
    }
  };

  /**
   * @function
   * Metodo para reemplazar entidades HTML de un string
   * @param {string} texto texto para ser validado
   * @returns {string}
   */
  const creaShort = (texto) => {
    let a = [
      "&aacute;",
      "&eacute;",
      "&iacute;",
      "&oacute;",
      "&uacute;",
      "&Aacute;",
      "&Eacute;",
      "&Iacute;",
      "&Oacute;",
      "&Uacute;",
      "&ntilde;",
      "&Ntilde;",
      "&nbsp;",
    ];
    let b = ["á", "é", "í", "ó", "ú", "Á", "É", "Í", "Ó", "Ú", "ñ", "Ñ", " "];
    texto = texto.replace(/<[^>]+>/g, "");
    for (let i = 0; i < a.length; i++) texto = texto.replaceAll(a[i], b[i]);
    texto = texto.trim();
    return texto;
  };

  /**
   * @function
   * Metodo para validar el texto usado en una petición HTTP
   * @param {string} texto texto para ser validado
   * @returns {boolean}
   */
  const validaQuery = (texto) => {
    let validar = [
      `"`,
      `.`,
      `?`,
      `=`,
      `'`,
      `*`,
      `<`,
      `>`,
      `;`,
      `(`,
      `)`,
      `{`,
      `}`,
      `[`,
      `]`,
      `,`,
      `_`,
      `:`,
      "`",
    ];
    for (let i = 0; i < validar.length; i++) {
      if (texto.includes(validar[i])) return false;
    }
    return true;
  };

  const calculaPorcentaje = (descuento, tachado) => {
    let por = ((tachado - descuento) * 100) / tachado;
    while (por % 1 != 0) {
      por = Math.round(por);
    }
    return por;
  };

  useEffect(() => {
    const urlPath = window.location.pathname;
    const segments = urlPath.split("/").filter(Boolean);
    const categoryas = segments[0];
    axios
      .get(
        process.env.REACT_APP_BACK_URL +
          `/api/marcaje/obtener_marcaje_producto?producto=${categoryas}`
      )
      .then((response) => {
        setMarcaje(response.data.marcaje);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    console.log(fotos);
  }, [fotos]);

  return (
    <>
      {marcaje && (
        <Helmet>
          <link
            rel="canonical"
            href={process.env.REACT_APP_FRONT_URL + "/" + slug.current}
          />
          {(marcaje.title && marcaje.title != "" && marcaje.title != null) ? (
            <title>{marcaje.title + " | Dreamtec"}</title>
          ):(
            <title>{"Dreamtec"}</title>
          )}
          <meta name="description" content={marcaje.description} />
          <meta property="og:description" content={marcaje.description} />
          <meta property="og:title" content={marcaje.title + " | Dreamtec"} />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={process.env.REACT_APP_FRONT_URL + "/" + producto[0]?.slug}
          />
          <meta
            property="og:image"
            content={process.env.REACT_APP_FRONT_URL + producto[0]?.foto_1}
          />
        </Helmet>
      )}
      {producto[0] != null && (
        <Helmet>
          <script type="application/ld+json">
            {producto[0] != null &&
              producto[0].upc != null &&
              JSON.stringify(
                [
                  {
                    "@context": "https://schema.org",
                    "@type": "Product",
                    name: producto[0].nombre,
                    description: handleJsonProducto(producto[0].descripcion),
                    image: process.env.REACT_APP_FRONT_URL + producto[0].foto_1,
                    offers: {
                      "@type": "Offer",
                      availability: "https://schema.org/InStock",
                      price: producto[0].precio_descuento,
                      priceCurrency: "CLP",
                      url:
                        process.env.REACT_APP_FRONT_URL +
                        "/" +
                        producto[0].slug,
                      condition: "new",
                    },
                    brand: {
                      "@type": "Brand",
                      name: producto[0].marca,
                    },
                    url:
                      process.env.REACT_APP_FRONT_URL + "/" + producto[0].slug,
                    sku: producto[0].sku,
                    productID: producto[0].id,
                    ["gtin" + producto[0].upc.length]: producto[0].upc,
                  },
                  {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    itemListElement: [
                      {
                        "@type": "ListItem",
                        position: 1,
                        name: producto[0].c_hija,
                        item:
                          process.env.REACT_APP_FRONT_URL +
                          "/" +
                          producto[0].c_padre +
                          "/" +
                          producto[0].c_hija,
                      },
                    ],
                  },
                ],
                null,
                2
              )}
          </script>
        </Helmet>
      )}

      {isLoaded ? (
        <div className="container-fluid">
          <div className="container mt-5 mb-0  d-md-block d-lg-block">
            <div className="row">
              <div className="col-lg-11 col-md-11 col-12 px-4">
                <a
                  className="fs-14 text-black tdeconone"
                  role="button"
                  onClick={() => {
                    location.href = "/";
                  }}
                >
                  Inicio
                </a>
                <span className="text-black">
                  <i className="fa-solid fa-angle-right px-2 fs-12"></i>
                </span>
                <a
                  className="fs-14 text-black tdeconone"
                  role="button"
                  onClick={() => {
                    location.href = `/${producto[0].c_padre}`;
                  }}
                >
                  {producto[0].c_padre &&
                    producto[0]?.c_padre?.charAt(0).toUpperCase() +
                      producto[0]?.c_padre?.slice(1).replace("-", " ")}
                </a>
                <span className="text-black">
                  <i className="fa-solid fa-angle-right px-2 fs-12"></i>
                </span>
                <a
                  className="fs-14 text-black tdeconone"
                  role="button"
                  onClick={() => {
                    location.href = `/${producto[0]?.c_padre}/${producto[0]?.c_hija}`;
                  }}
                >
                  {producto[0].c_hija &&
                    producto[0]?.c_hija?.charAt(0).toUpperCase() +
                      producto[0]?.c_hija?.slice(1).replace("-", " ")}
                </a>
                <span className="text-black d-xs-none" id="span-movil">
                  <i className="fa-solid fa-angle-right px-2 fs-12 "></i>
                </span>
                <span
                  className="fw-bold fs-6 text-turq d-xs-none"
                  id="span-movil-1"
                >
                  {producto[0]?.nombre}
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row row-productos-home">
              <div className="col-12 mt-3 mb-5">
                <div className="row justify-content-center">
                  <div className="row justify-content-between">
                    <div className="col-lg-9 col-md-9 col-sm-11 col-12 mb-3">
                      <p className="nombre-marca fs-6">
                        <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                        {producto[0]?.marca}
                      </p>
                      <h1 className="text-black fs-4 fw-bold">
                        {producto[0].marcaje &&
                        producto[0].marcaje.h1 != null &&
                        producto[0].marcaje.h1 != ""
                          ? producto[0].marcaje.h1
                          : producto[0]?.nombre}
                      </h1>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-11 col-sm-11 col-12 text-end mt-2 px-0">
                      <div className="col-md-12 pe-3 mt-3">
                        <span className="sku-detalle">
                          SKU <strong>{producto[0]?.sku}</strong>
                        </span>
                      </div>
                      {producto[0].pn && (
                        <div className="col-md-12 mt-3 pe-3">
                          <span className="sku-detalle">
                            P/N <strong>{producto[0]?.pn}</strong>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                      <div className="row">
                        <div className="col-lg-1 d-xs-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block mb-2">
                          <div
                            className="rect-img-container-b text-center"
                            width="95%"
                          >
                            {
                              // Recorremos las fotos
                              [
                                "foto_1",
                                "foto_2",
                                "foto_3",
                                "foto_4",
                                "foto_5",
                                "foto_6",
                              ].map((fotoKey) => {
                                const imageUrl = producto[0][fotoKey]; // URL de la imagen
                                const altTextKey = `alt_${fotoKey}`; // Clave del texto alternativo
                                const altText = producto[0][altTextKey]; // Texto alternativo correspondiente

                                // Validar si existe la imagen
                                return imageUrl ? (
                                  <img
                                    key={fotoKey}
                                    loading="eager"
                                    src={`${process.env.REACT_APP_FRONT_URL}${imageUrl}`}
                                    className={`rect-img-container-b img-producto-miniatura cursor_pointer img_click ${
                                      currentPicture === fotoKey ? "active" : ""
                                    }`}
                                    alt={altText || "Imagen del producto"} // Asignar el alt text
                                    title={producto[0].nombre}
                                    width={47}
                                    height={47}
                                    onMouseOver={() =>
                                      setCurrentPicture(fotoKey)
                                    }
                                  />
                                ) : null;
                              })
                            }
                          </div>
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row">
                            <div className="col-12 d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                              <img
                                loading="eager"
                                src={
                                  process.env.REACT_APP_FRONT_URL +
                                  `${producto[0][currentPicture]}`
                                }
                                className="img-producto-miniatura cursor_pointer img_click img-fluid"
                                width={350}
                                height={300}
                                alt={
                                  producto[0][`alt_${currentPicture}`] ||
                                  "Imagen del producto"
                                }
                                title={producto[0].nombre}
                              />
                            </div>

                            <div className="row justify-content-start px-2 d-xxl-none d-xl-none d-lg-none d-md-none d-sm-none d-none">
                              <div className="contador-fotos-detalle">
                                <span className="texto-contador-fotos-detalle">
                                  <span
                                    className="texto-contador-fotos-detalle"
                                    id="inner_item_actual"
                                    ref={inner_item}
                                  >
                                    {mostrando}
                                  </span>{" "}
                                  / {totalFotos.current}
                                </span>
                              </div>
                            </div>

                            <div className="col-12 d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block d-block mb-3">
                              {/* <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-interval="false" data-bs-ride="carousel" data-bs-touch="true">

                                <div className="carousel-indicators">
                                  {fotos.map((pic, index) => (

                                    <>
                                      {pic ? (

                                        <button

                                          key={index}
                                          type="button"
                                          data-bs-target="#carouselExampleSlidesOnly"
                                          data-bs-slide-to={index.toString()}
                                          className={index === 0 ? "active" : ""}
                                          aria-current={index === 0 ? "true" : ""}
                                          aria-label={`Slide ${index + 1}`}
                                          id={`indicador_${index}`}

                                        ></button>

                                      ) : (
                                        <></>
                                      )}

                                    </>

                                  ))}
                                </div>


                                <div className="carousel-inner">


                        
                                </div>

                              </div> */}

                              <Carousel
                                touch={true}
                                controls={false}
                                indicators={true}
                                slide={true}
                                id="slide-movil"
                              >
                                {fotos.map((pic, index) => (
                                  <Carousel.Item key={index}>
                                    <img
                                      className="d-block w-100"
                                      src={
                                        process.env.REACT_APP_FRONT_URL +
                                        `${pic}`
                                      }
                                      alt={pic.alt}
                                    />
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                            </div>
                          </div>
                        </div>

                        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12 mb-2">
                          {producto[0].precio_tachado != 0 ? (
                            <>
                              <div className="row mb-2">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 f-16 text-secondary font-weight-bold align-self-center">
                                  Precio Normal
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-secondary text-end align-self-center">
                                  <span className="text-decoration-line-through Gris f-24 fw-bold">
                                    {producto[0].precio_tachado.toLocaleString(
                                      "es-CL",
                                      {
                                        style: "currency",
                                        currency: "CLP",
                                      }
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6  f-16 align-self-center">
                                  Descuento
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-end align-self-center">
                                  <span className="Desc text-white px-2 py-1 rounded rounded-3 f-20">
                                    {htmlReactParser("&mdash;") +
                                      calculaPorcentaje(
                                        producto[0].precio_descuento,
                                        producto[0].precio_tachado
                                      ) +
                                      " %"}
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : null}

                          <p className="text-black fs-1 fw-bold mb-0">
                            {producto[0].precio_descuento.toLocaleString(
                              "es-CL",
                              {
                                style: "currency",
                                currency: "CLP",
                              }
                            )}
                          </p>

                          <span className="texto-precio-transf">
                            Sólo pagando con transferencia
                          </span>
                          <p className="fs-14 text-azul lh-1 mb-1 mt-3">
                            <strong className="fs-3 mb-1">
                              {producto[0].precio.toLocaleString("es-CL", {
                                style: "currency",
                                currency: "CLP",
                              })}
                            </strong>{" "}
                          </p>
                          <p className="fs-14 text-azul">
                            con otros medios de pago ó 6 cuotas de{" "}
                            <strong>
                              {(producto[0].precio / 6).toLocaleString(
                                "es-CL",
                                {
                                  style: "currency",
                                  currency: "CLP",
                                }
                              )}
                            </strong>{" "}
                            sin interés
                          </p>

                          <p className="fs-12 text-white mb-2 mt-1">
                            <span className="bg-turq px-2 py-1">
                              Sólo pagando con Mercado Pago
                            </span>
                          </p>
                          <button
                            type="button"
                            aria-label="Ver los medios de pago"
                            className="bg-transparent border-0 text-azul-black small"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-medios-pagos"
                          >
                            <i className="fas fa-money-bill-alt"></i> Ver todos
                            los medios de pago
                          </button>

                          <MediosPago />

                          <div className="container justify-content-end px-0 d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block d-xs-block mt-3">
                            <Simulator
                              stock={producto[0].stock}
                              sku={producto[0].sku}
                              cantidad={producto[0].cantidad_carro}
                              producto={producto[0]}
                            />
                          </div>

                          {producto[0].caracteristicas && (
                            <div className="row mt-3 d-md-block">
                              <div className="col-12 mb-3 fz-14 col-caract-dest">
                                <p className="fz-14 p-titulo-caract-dest text-dark fw-bolder mb-2">
                                  Características Destacadas
                                </p>
                                {producto[0].caracteristicas
                                  .split("</p>")
                                  .map((caracteristica, index) => (
                                    <p key={index} className="fz-12 mb-2">
                                      {creaShort(caracteristica) != "" &&
                                        creaShort(caracteristica)}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {producto?.[0]?.marcaje && producto[0].marcaje.h2 ? (
                        <div className="row">
                          <div className="col-12 d-flex align-self-center">
                            <h2>
                              <span className="fw-regular">
                                Revisa más detalle de:&nbsp;
                              </span>
                              <strong className="text-dark">
                                {producto[0].marcaje.h2}
                              </strong>
                            </h2>
                          </div>
                        </div>
                      ) : null}

                      <div className="row mt-3 position-top">
                        <div className="col-lg-12">
                          <hr />
                          <ul
                            className="nav nav-tabs d-xs-none d-md-flex"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className="link-tab-producto active"
                                id="nav-home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-home"
                                type="button"
                                role="tab"
                                aria-controls="nav-home"
                                aria-selected="true"
                              >
                                <div className="texto-opciones">
                                  DESCRIPCIÓN
                                </div>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="link-tab-producto"
                                id="nav-opniones-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-opniones"
                                type="button"
                                role="tab"
                                aria-controls="nav-opniones"
                                aria-selected="false"
                              >
                                <div className="texto-opciones">OPINIONES</div>
                              </button>
                            </li>
                            {/* 
                            <li className="nav-item" role="presentation">
                              <button
                                className="link-tab-producto"
                                id="nav-videos-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-videos"
                                type="button"
                                role="tab"
                                aria-controls="nav-videos"
                                aria-selected="false"
                              >
                                <h2 className="texto-opciones">VIDEOS / REVIEWS</h2>
                              </button>
                            </li>*/}
                            <li className="nav-item" role="presentation">
                              <button
                                className="link-tab-producto"
                                id="nav-preguntas-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-preguntas"
                                type="button"
                                role="tab"
                                aria-controls="nav-preguntas"
                                aria-selected="false"
                              >
                                <div className="texto-opciones">PREGUNTAS</div>
                              </button>
                            </li>
                          </ul>
                          <div className="tab-content" id="nav-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="nav-home"
                              role="tabpanel"
                              aria-labelledby="nav-home-tab"
                            >
                              {/* Version Web */}
                              <div className="row d-xs-none d-md-block">
                                <div className="col-12 mt-3">
                                  {htmlReactParser(producto[0].short)}
                                </div>
                                <div className="col-12 mt-3">
                                  {htmlReactParser(producto[0].descripcion)}
                                </div>
                              </div>

                              {/* Version Movil */}
                              <a id="detalle"></a>
                              <div
                                className="accordion d-xs-block d-md-none"
                                id="accordionExample"
                              >
                                <div className="accordion-item border border-0">
                                  <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <div className="row d-xs-block d-md-none">
                                        <div className="col-12 mt-3">
                                          {htmlReactParser(producto[0].short)}
                                        </div>
                                        <div className="col-12 mt-3">
                                          {htmlReactParser(
                                            producto[0].descripcion
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <p
                                    className="accordion-header mb-3"
                                    id="headingOne"
                                  >
                                    <button
                                      className="accordion-button text-center"
                                      onClick={() => {
                                        cambiaFlecha();
                                        if (scroll == 1) setScroll(2);
                                        else {
                                          document
                                            .querySelector("#detalle")
                                            .scrollIntoView({
                                              behavior: "smooth",
                                            });
                                          setScroll(1);
                                        }
                                      }}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseOne"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      <i
                                        id="boton-acordeon"
                                        className="fas fa-arrow-circle-down text-turq fs-16"
                                      ></i>
                                      &nbsp;Ver Más Información
                                    </button>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-opniones"
                              role="tabpanel"
                              aria-labelledby="nav-opniones-tab"
                            >
                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-12 text-center mt-5">
                                  <i className="fas fa-screwdriver icono-construccion text-white"></i>
                                  <p className="text-turq mt-2">
                                    Sección en construcción
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-videos"
                              role="tabpanel"
                              aria-labelledby="nav-videos-tab"
                            >
                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-12 text-center mt-5">
                                  <i className="fas fa-screwdriver icono-construccion text-white"></i>
                                  <p className="text-turq mt-2">
                                    Sección en construcción
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-preguntas"
                              role="tabpanel"
                              aria-labelledby="nav-preguntas-tab"
                            >
                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-12 text-center mt-5">
                                  <i className="fas fa-screwdriver icono-construccion text-white"></i>

                                  <p className="text-turq mt-2">
                                    Sección en construcción
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 d-xxl-block d-xl-block d-lg-block d-md-block d-sm-block d-block mt-3">
                      <div className="container justify-content-end px-0 d-xxl-block d-xl-block d-lg-block d-md-block d-sm-none d-xs-none">
                        <Simulator
                          stock={producto[0].stock}
                          sku={producto[0].sku}
                          cantidad={producto[0].cantidad_carro}
                          producto={producto[0]}
                        />
                      </div>

                      <div className="container">
                        <div className="row">
                          <div className="bg-white btn-borde-turq rounded1 mt-3">
                            <div className="row justify-content-center">
                              <div className="col-lg-11 col-md-11 col-11">
                                <p className="fs-6 text-black fw-semibold mt-2 mb-2">
                                  Medios de pago
                                </p>
                              </div>
                              <div className="col-lg-5 col-md-5 col-sm-4 col-4 my-2">
                                <img
                                  loading="lazy"
                                  src={
                                    process.env.REACT_APP_FRONT_URL +
                                    "/uploads/assets/Modales/logo-transferencia.png"
                                  }
                                  className="img-fluid"
                                  alt="pago aceptado con transferencia bancaria"
                                  title="pago aceptado con transferencia bancaria"
                                />
                              </div>

                              <div className="col-lg-5 col-md-5 col-sm-4 col-4 my-2">
                                <img
                                  loading="lazy"
                                  src={
                                    process.env.REACT_APP_FRONT_URL +
                                    "/uploads/assets/Modales/logo-mercadopago.png"
                                  }
                                  className="img-fluid"
                                  alt="pago aceptado con mercadopago"
                                  title="pago aceptado con mercadopago"
                                />
                              </div>
                              <div className="col-lg-5 col-md-5 col-sm-4 col-4 my-2">
                                <img
                                  loading="lazy"
                                  src={
                                    process.env.REACT_APP_FRONT_URL +
                                    "/uploads/assets/carrito/flow-pagos.webp"
                                  }
                                  className="img-fluid"
                                  alt="pago aceptado con Webpay"
                                  title="pago aceptado con Webpay"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-xs-12 bg-azul-black mt-3 rounded1 py-3">
                            <div className="row justify-content-center mb-2">
                              <div className="col-lg-1 col-md-2 col-2 px-0">
                                <img
                                  loading="lazy"
                                  src={
                                    process.env.REACT_APP_FRONT_URL +
                                    "/uploads/assets/icono-candado.png"
                                  }
                                  className="img-fluid"
                                  alt="Protección"
                                  title="Protección"
                                />
                              </div>
                              <div className="col-lg-10 col-md-9 col-9">
                                <p className="fs-6 text-turq">
                                  Garantía del fabricante
                                </p>
                                <p className="fs-12 text-white mb-1">
                                  La Garantía del Fabricante es aquella que
                                  entrega la marca del producto y respecto de la
                                  cuál es éste responsable no Dreamtec ni sus
                                  filiales. La Información y condiciones de
                                  dicha garantía debe encontrarse debidamente
                                  informadas por el Proveedor en el empaque del
                                  producto.
                                </p>
                              </div>
                            </div>
                            <div className="row align-items-start justify-content-center mb-4">
                              <div className="col-lg-1 col-md-2 col-2 px-1">
                                <img
                                  loading="lazy"
                                  src={
                                    process.env.REACT_APP_FRONT_URL +
                                    "/uploads/assets/icono-detalle-prod-devolucion.png"
                                  }
                                  className="img-fluid"
                                  alt="Envío de producto"
                                  title="Envío de producto"
                                />
                              </div>
                              <div className="col-lg-10 col-md-9 col-9">
                                <p className="fs-6 text-turq">
                                  Devolución gratis
                                </p>

                                <button
                                  className="border-0 bg-gris-c text-azul-black fs-12 py-1 rounded-pill px-4 btn-gris-hover btn-sombra"
                                  type="button"
                                  role="button"
                                  aria-label="Ver carro de compra"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modal-devolucion"
                                >
                                  Conocer más
                                </button>

                                <DevolucionGratis />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row justify-content-center">
            <div className="col-9 mx-5">
              <h2 className="fw-bold text-dark fs-5 mt-5 mb-4">
                PRODUCTOS RELACIONADOS
              </h2>
              {producto != [] && <RelacionadosCards producto={producto} />}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="container py-5 my-5">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="fw-bold fs-1 mb-2 text-dark">Cargando</h1>
                <div
                  className="spinner-border color-35 spinner-detail"
                  role="status"
                >
                  <span className="visually-hidden">Cargando...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetail;
