/**
 * Página Admin Marcaje:
 *
 * acá se crean o modifica el marcaje de los productos, marcas, categorias, etc.
 *
 * @module AdminMarcajejsx
 */

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import $ from "jquery";

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

import "../../assets/summernote/summernote-lite.min.css";
import "../../assets/summernote/summernote-lite.min.js";

const Marcaje = (props) => {
  const navigate = useNavigate();
  const [titleCount, setTitleCount] = useState(0);
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [marcas, setMarcas] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const [productName, setProductName] = useState("");
  const [sku, setSku] = useState("");
  const [brand, setBrand] = useState("");
  const [categoria_padre, setCategoria_padre] = useState([]);
  const [selected_categoria_padre, setSelected_categoria_padre] = useState([]);
  const [categoria_hija, setCategoria_hija] = useState([]);
  const [selected_categoria_hija, setSelected_categoria_hija] = useState([]);
  const [msg, setMsg] = useState("");
  const [tipo, setTipo] = useState("");
  const [busqueda, setBusqueda] = useState("");
  const accessToekn = sessionStorage.getItem("access");
  const [datos, setDatos] = useState([]);
  const [existenDatos, setExistenDatos] = useState(false);
  const [producto, setProducto] = useState([]);
  const [marca, setMarca] = useState([]);
  const [categoriaPadre, setCategoriaPadre] = useState([]);
  const [categoriaHija, setCategoriaHija] = useState([]);
  const [fotos, setFotos] = useState([null, null, null, null, null, null]);
  const [alt, setAlt] = useState([]);
  const [marcajeTitle, setMarcajeTitle] = useState("");
  const [marcajeDescription, setMarcajeDescription] = useState("");
  const [altMarca, setAltMarca] = useState("");
  const [idProducto, setIdProducto] = useState("");
  const [marcajeH2, setMarcajeH2] = useState("");
  const [marcajeH1, setMarcajeH1] = useState("");
  const [camp, setCamp] = useState("");
  const [campana, setCampana] = useState([]);
  const [altCampana, setAltCampana] = useState("");

  const [fotosSrc, setFotosSrc] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  useEffect(() => {
    setTitleCount(marcajeTitle.length);
  }, [marcajeTitle]);
  
  useEffect(() => {
    setDescriptionCount(marcajeDescription.length);
  }, [marcajeDescription]);

  const handleTitleChange = (e) => {
    const value = e;
    if (value.length <= 65) {
      setMarcajeTitle(value);
      setTitleCount(value.length);
    }
  };
  
  const handleDescriptionChange = (e) => {
    const value = e;
    if (value.length <= 156) {
      setMarcajeDescription(value);
      setDescriptionCount(value.length);
    }
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/marca/get_all_marcas`)
      .then((response) => setMarcas(response.data))
      .catch((error) => console.log(error));

    axios
      .get(
        process.env.REACT_APP_BACK_URL +
          `/api/admin/categoria/get_all_categoria`
      )
      .then((response) => setCategoria_padre(response.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selected_categoria_padre !== "") {
      axios
        .get(
          process.env.REACT_APP_BACK_URL +
            `/api/admin/categoria/get_all_categoria_hija_by_padre/${selected_categoria_padre}`
        )
        .then((response) => setCategoria_hija(response.data))
        .catch((error) => console.log(error));
    } else {
      setCategoria_hija([]);
    }
  }, [selected_categoria_padre]);

  const validateForm = () => {
    var validarHTML = /<("[^"]*"|'[^']*'|[^'">])*>/;

    if (busqueda == 1 && sku == "") {
      setMsg("Debe ingresar el SKU");
      setMensaje("Debe ingresar el SKU-rojo");
      setTipo(2);
      return false;
    }

    if (busqueda == 1 && sku != "") {
      if (validarHTML.test(sku)) {
        setMsg("Debe ingresar un SKU válido");
        setMensaje("Debe ingresar un SKU válido-rojo");
        setTipo(2);
        return false;
      }
    }

    if (busqueda == 2 && selected_categoria_padre == "") {
      setMsg("Debe seleccionar una categoria padre");
      setMensaje("Debe seleccionar una categoria padre-rojo");
      setTipo(2);
      return false;
    }

    if (busqueda == 3 && selected_categoria_padre == "") {
      setMsg("Debe seleccionar una categoria padre");
      setMensaje("Debe seleccionar una categoria padre-rojo");
      setTipo(2);
      return false;
    }

    if (busqueda == 3 && selected_categoria_hija == "") {
      setMsg("Debe seleccionar una categoria hija");
      setMensaje("Debe seleccionar una categoria hija-rojo");
      setTipo(2);
      return false;
    }

    if (busqueda == 4 && brand == "") {
      setMsg("Debe seleccionar una marca");
      setMensaje("Debe seleccionar una marca-rojo");
      setTipo(2);
      return false;
    }

    return true;
  };

  const handleSubmitBuscar = (e) => {
    e.preventDefault();

    /* Setear ciertos valores a nulo */
    setIdProducto("");
    setAltMarca("");
    setMarcajeDescription("");
    setMarcajeTitle("");
    setAlt([]);
    setSelected_categoria_hija("");
    setSelected_categoria_padre("");
    setBrand("");
    setSku("");

    setMensaje("Buscando resultados-alert");
    setMsg("");
    setTipo(0);

    if (validateForm()) {
      let id;
      if (busqueda == 1) {
        id = sku;
      } else if (busqueda == 2) {
        id = selected_categoria_padre;
      } else if (busqueda == 3) {
        id = selected_categoria_hija;
      } else if (busqueda == 4) {
        id = brand;
      } else if (busqueda == 5) {
        id = camp;
      }

      axios
        .get(
          process.env.REACT_APP_BACK_URL +
            "/api/marcaje/buscar_marcaje?tipo=" +
            busqueda +
            "&id=" +
            id,
          {
            headers: {
              Authorization: `Bearer ${accessToekn}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {

          setExistenDatos(true);

          setMsg("Datos encontrados");
          setMensaje("Datos encontrados-verde");
          setTipo(1);

          if (busqueda == 1) {
            setProducto(response.data);
          } else if (busqueda == 2) {
            setCategoriaPadre(response.data);
          } else if (busqueda == 3) {
            setCategoriaHija(response.data);
          } else if (busqueda == 4) {
            setMarca(response.data);
          } else if (busqueda == 5) {
            setCampana(response.data);
          }

          setTimeout(() => {
            setMsg("");
            setTipo(0);
          }, 3000);
        })
        .catch((error) => {
          setExistenDatos(false);
          // console.error(error);
          const errorMessage = error?.response?.data?.message
            ? error.response.data.message + "-rojo"
            : "No se han encontrado resultados, intente nuevamente-rojo";
          setMensaje(errorMessage);
          setTipo(2);
          setTimeout(() => {
            setMsg("");
            setTipo(0);
          }, 5000);
        });
    }
  };

  useEffect(() => {
    if (producto && producto.marcaje) {
      setMarcajeTitle(producto.marcaje[1].title);
      setMarcajeDescription(producto.marcaje[1].description);
      setMarcajeH2(producto.marcaje[1].h2 || ""); // Prellenar h2 si existe
      setMarcajeH1(producto.marcaje[1].h1 || ""); // Prellenar h1 si existe
    }
    if (producto) {
      setSku(producto.sku);
      setIdProducto(producto.id);
    }
    if (producto?.fotos) {
      setAlt(producto.fotos.map((foto) => foto.alt || "")); // Inicializa con los valores existentes o vacíos
    }
  }, [producto]);

  useEffect(() => {
    if (categoriaPadre && categoriaPadre.marcaje) {
      setMarcajeTitle(categoriaPadre.marcaje[1].title);
      setMarcajeDescription(categoriaPadre.marcaje[1].description);
    }
    if (categoriaPadre) {
      setSelected_categoria_padre(categoriaPadre.id);
    }
  }, [categoriaPadre]);

  useEffect(() => {
    if (categoriaHija && categoriaHija.marcaje) {
      setMarcajeTitle(categoriaHija.marcaje[1].title);
      setMarcajeDescription(categoriaHija.marcaje[1].description);
    }
    if (categoriaHija) {
      setSelected_categoria_hija(categoriaHija.id);
    }
  }, [categoriaHija]);

  useEffect(() => {
    if (marca) {
      setBrand(marca.id);
      setAltMarca(marca.alt);
    }
    if(marca && marca.marcaje){
      setMarcajeTitle(marca.marcaje[1].title);
      setMarcajeDescription(marca.marcaje[1].description);
    }
  }, [marca]);

  useEffect(() => {
    if(campana && campana.marcaje){
      setMarcajeTitle(campana.marcaje[1].title);
      setMarcajeDescription(campana.marcaje[1].description);
      setAltCampana(campana.alt);
      setMarcajeH1(campana.marcaje[1].h1);
    }
  }, [campana]);

  const handleBuscar = (numero) => {
    setBusqueda(numero);
  };

  const handleVolver = () => {
    setBusqueda();
    setDatos([]);
    setExistenDatos(false);
    setSku("");
    setSelected_categoria_hija("");
    setSelected_categoria_padre("");
    setBrand("");
    setProducto([]);
    setCategoriaHija([]);
    setCategoriaPadre([]);
    setMarca([]);
  };

  const handleEditarMarcaje = async (e) => {
    e.preventDefault();

    let isValid = true;
    let payload = new FormData(); // Usaremos FormData para la solicitud
    payload.append("tipo", busqueda); // Agrega el tipo según el modo de búsqueda
    if (busqueda == 1) {
      payload.append("id", idProducto);
    } else if (busqueda == 2) {
      payload.append("id", selected_categoria_padre);
    } else if (busqueda == 3) {
      payload.append("id", selected_categoria_hija);
    } else if (busqueda == 4) {
      payload.append("id", brand);
    } else if (busqueda == 5) {
      payload.append("id", campana.id);
    }

    const validateLengths = (fields) => {
      const maxLengths = {
        title: 65,
        description: 156,
        h2: 500,
        h1: 500, // Longitud máxima para h1
      };

      for (let field in fields) {
        const value = fields[field];
        const maxLength = maxLengths[field];

        if (value && value.length > maxLength) {
          return `El campo '${field}' excede el máximo de ${maxLength} caracteres.`;
        }
      }

      return null;
    };

    const validateNoHTML = (fields) => {
      const htmlPattern = /<\/?[a-z][\s\S]*>/i;

      for (let field in fields) {
        const value = fields[field];
        if (value && htmlPattern.test(value)) {
          return `El campo '${field}' contiene HTML, lo cual no está permitido.`;
        }
      }

      return null;
    };

    // Validar longitudes de campos
    const validationError = validateLengths({
      title: marcajeTitle,
      description: marcajeDescription,
    });

    if (validationError) {
      setMensaje(validationError + "-rojo");
      setTipo(2);
      return;
    }

    // Validar presencia de HTML
    const htmlError = validateNoHTML({
      title: marcajeTitle,
      description: marcajeDescription,
    });

    if (htmlError) {
      setMensaje(htmlError + "-rojo");
      setTipo(2);
      return;
    }

    if (busqueda === 1) {
      if (!marcajeTitle || !marcajeDescription || !marcajeH2 || !marcajeH1) {
        setMensaje("Todos los campos de marcaje son obligatorios-rojo");
        setTipo(2);
        return;
      }

      const validationError = validateLengths({
        title: marcajeTitle,
        description: marcajeDescription,
        h2: marcajeH2,
        h1: marcajeH1,
      });

      if (validationError) {
        setMensaje(validationError + "-rojo");
        setTipo(2);
        return;
      }

      const htmlError = validateNoHTML({
        title: marcajeTitle,
        description: marcajeDescription,
        h2: marcajeH2,
        h1: marcajeH1,
      });

      if (htmlError) {
        setMensaje(htmlError + "-rojo");
        setTipo(2);
        return;
      }

      payload.append("title", marcajeTitle.trim());
      payload.append("description", marcajeDescription.trim());
      payload.append("h2", marcajeH2.trim());
      payload.append("h1", marcajeH1.trim());

      const fotosData = producto.fotos.map((foto, index) => {
        const altValue = alt[index];
        if (!altValue || altValue.trim() === "") {
          setMensaje(
            `Debe completar el campo Alt para la imagen ${index + 1}-rojo`
          );
          setTipo(2);
          isValid = false;
        }
        return { id: foto.id, alt: altValue.trim() };
      });

      if (!isValid) return;
      payload.append("fotos", JSON.stringify(fotosData));
    } else if (busqueda === 2 || busqueda === 3) {
      if (!marcajeTitle || !marcajeDescription) {
        setMensaje("Debe completar Title y Description-rojo");
        setTipo(2);
        return;
      }
      payload.append("title", marcajeTitle.trim());
      payload.append("description", marcajeDescription.trim());
    } else if (busqueda === 4) {
      if (!altMarca || altMarca.trim() === "") {
        setMensaje(
          "Debe completar el campo Alt para la imagen de la marca-rojo"
        );
        setTipo(2);
        return;
      }

      // Validación de Meta Title y Meta Description
      if (!marcajeTitle || !marcajeDescription) {
        setMensaje("Debe completar Title y Description para la marca-rojo");
        setTipo(2);
        return;
      }

      payload.append("title", marcajeTitle.trim());
      payload.append("description", marcajeDescription.trim());
      payload.append("alt", altMarca.trim());
    } else if (busqueda === 5){
      if (!altCampana || altCampana.trim() === "") {
        setMensaje(
          "Debe completar el campo Alt para la imagen de la campaña-rojo"
        );
        setTipo(2);
        return;
      }

      // Validación de Meta Title y Meta Description
      if (!marcajeTitle || !marcajeDescription) {
        setMensaje("Debe completar Title y Description para la campaña-rojo");
        setTipo(2);
        return;
      }

      if (!marcajeH1){
        setMensaje("Debe completar el H1 para la campaña-rojo");
        setTipo(2);
        return;
      }

      payload.append("title", marcajeTitle.trim());
      payload.append("description", marcajeDescription.trim());
      payload.append("alt", altCampana.trim());
      payload.append("h1", marcajeH1.trim());
    }

    // Enviar la solicitud al backend
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/marcaje/crear_modificar_marcaje`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMensaje("Marcaje creado/modificado con éxito-verde");
      setTipo(1);
    } catch (error) {
      console.error(
        "Error en la solicitud:",
        error.response?.data || error.message
      );
      setMensaje(error.response?.data?.mensaje || "Error inesperado-rojo");
      setTipo(2);
    }
  };

  return (
    <div>
      <AdminSidebar />
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />

      <div className="regular">
        <h1>
          Dreamtec - <span>Administrador de SEO</span>
        </h1>
        <div className="shadow">
          <div className="row">
            <div className="col-12 text-start my-3">
              {busqueda == 1 && (
                <>
                  <h2>Buscar producto</h2>
                </>
              )}
              {busqueda == 2 && (
                <>
                  <h2>Buscar categoria padre</h2>
                </>
              )}
              {busqueda == 3 && (
                <>
                  <h2>Buscar categoria hija</h2>
                </>
              )}
              {busqueda == 4 && (
                <>
                  <h2>Buscar marca</h2>
                </>
              )}
              {busqueda == 5 && (
                <>
                  <h2>Buscar campaña</h2>
                </>
              )}
              {!busqueda && (
                <>
                  <h2>Seleccione una opción a buscar</h2>
                </>
              )}
            </div>

            <div className="col-12">
              {!busqueda && (
                <>
                  <div className="row mb-5">
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 mb-2">
                      <button
                        type="button"
                        className="agregar"
                        title="Producto"
                        aria-label="Producto"
                        onClick={() => {
                          handleBuscar(1);
                        }}
                      >
                        Producto
                      </button>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 mb-2">
                      <button
                        type="button"
                        className="agregar"
                        title="Categoria Padre"
                        aria-label="Categoria Padre"
                        onClick={() => {
                          handleBuscar(2);
                        }}
                      >
                        Categoria Padre
                      </button>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 mb-2">
                      <button
                        type="button"
                        className="agregar"
                        title="Categoria Padre"
                        aria-label="Categoria Padre"
                        onClick={() => {
                          handleBuscar(3);
                        }}
                      >
                        Categoria Hija
                      </button>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 mb-2">
                      <button
                        type="button"
                        className="agregar"
                        title="Marca"
                        aria-label="Marca"
                        onClick={() => {
                          handleBuscar(4);
                        }}
                      >
                        Marca
                      </button>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 mb-2">
                      <button
                        type="button"
                        className="agregar"
                        title="Marca"
                        aria-label="Marca"
                        onClick={() => {
                          handleBuscar(5);
                        }}
                      >
                        Campaña
                      </button>
                    </div>
                  </div>
                </>
              )}

              {busqueda == 1 && (
                <>
                  <form onSubmit={handleSubmitBuscar}>
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label className="fw-bold mx-1 form-label">
                          SKU: &nbsp;<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          placeholder="Ingresa SKU"
                          value={sku}
                          onChange={(e) => setSku(e.target.value.toUpperCase())}
                        />
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label
                          className="fw-bold mx-1 form-label"
                          style={{ visibility: "hidden" }}
                        >
                          Buscar: &nbsp;<span className="text-danger">*</span>
                        </label>
                        <button
                          aria-label="Buscar"
                          className="btnGreen w-100"
                          type="submit"
                        >
                          Buscar
                        </button>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label
                          className="fw-bold mx-1 form-label"
                          style={{ visibility: "hidden" }}
                        >
                          Volver atrás: &nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <button
                          aria-label="Cancelar"
                          className="cancel w-100"
                          onClick={() => {
                            handleVolver();
                          }}
                        >
                          Volver atrás
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}

              {busqueda == 2 && (
                <>
                  <form onSubmit={handleSubmitBuscar}>
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label className="fw-bold mx-1 form-label">
                          Categoria padre: &nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          value={selected_categoria_padre}
                          onChange={(e) =>
                            setSelected_categoria_padre(e.target.value)
                          }
                          className="form-control rounded-pill"
                          required
                        >
                          <option value="">Selecciona una categoria</option>
                          {categoria_padre.map((categoria) => (
                            <option key={categoria.id} value={categoria.id}>
                              {categoria.categoria.charAt(0).toUpperCase() +
                                categoria.categoria.slice(1)}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label
                          className="fw-bold mx-1 form-label"
                          style={{ visibility: "hidden" }}
                        >
                          Buscar: &nbsp;<span className="text-danger">*</span>
                        </label>
                        <button
                          aria-label="Buscar"
                          className="btnGreen w-100"
                          type="submit"
                        >
                          Buscar
                        </button>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label
                          className="fw-bold mx-1 form-label"
                          style={{ visibility: "hidden" }}
                        >
                          Volver atrás: &nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <button
                          aria-label="Cancelar"
                          className="cancel w-100"
                          onClick={() => {
                            handleVolver();
                          }}
                        >
                          Volver atrás
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}

              {busqueda == 3 && (
                <>
                  <form onSubmit={handleSubmitBuscar}>
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label className="fw-bold mx-1 form-label">
                          Categoria padre: &nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          value={selected_categoria_padre}
                          onChange={(e) =>
                            setSelected_categoria_padre(e.target.value)
                          }
                          className="form-control rounded-pill"
                          required
                        >
                          <option value="">Selecciona una categoria</option>
                          {categoria_padre.map((categoria) => (
                            <option key={categoria.id} value={categoria.id}>
                              {categoria.categoria.charAt(0).toUpperCase() +
                                categoria.categoria.slice(1)}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label className="fw-bold mx-1 form-label">
                          Categoria Hija: &nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          value={selected_categoria_hija}
                          onChange={(e) =>
                            setSelected_categoria_hija(e.target.value)
                          }
                          className="form-control rounded-pill"
                          required
                        >
                          <option value="">
                            Selecciona una categoria hija
                          </option>
                          {categoria_hija.map((categoria) => (
                            <option key={categoria.id} value={categoria.id}>
                              {categoria.hija.charAt(0).toUpperCase() +
                                categoria.hija.slice(1)}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label
                          className="fw-bold mx-1 form-label"
                          style={{ visibility: "hidden" }}
                        >
                          Buscar: &nbsp;<span className="text-danger">*</span>
                        </label>
                        <button
                          aria-label="Buscar"
                          className="btnGreen w-100"
                          type="submit"
                        >
                          Buscar
                        </button>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label
                          className="fw-bold mx-1 form-label"
                          style={{ visibility: "hidden" }}
                        >
                          Volver atrás: &nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <button
                          aria-label="Cancelar"
                          className="cancel w-100"
                          onClick={() => {
                            handleVolver();
                          }}
                        >
                          Volver atrás
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}

              {busqueda == 4 && (
                <>
                  <form onSubmit={handleSubmitBuscar}>
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label className="fw-bold mx-1 form-label">
                          Marca: &nbsp;<span className="text-danger">*</span>
                        </label>
                        <select
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                          className="form-control rounded-pill"
                          required
                        >
                          <option value="">Selecciona una marca</option>
                          {marcas?.map((marca) => (
                            <option key={marca.id} value={marca.id}>
                              {marca.nombre_mostrar}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label
                          className="fw-bold mx-1 form-label"
                          style={{ visibility: "hidden" }}
                        >
                          Buscar: &nbsp;<span className="text-danger">*</span>
                        </label>
                        <button
                          aria-label="Buscar"
                          className="btnGreen w-100"
                          type="submit"
                        >
                          Buscar
                        </button>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label
                          className="fw-bold mx-1 form-label"
                          style={{ visibility: "hidden" }}
                        >
                          Volver atrás: &nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <button
                          aria-label="Cancelar"
                          className="cancel w-100"
                          onClick={() => {
                            handleVolver();
                          }}
                        >
                          Volver atrás
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}

              {busqueda == 5 && (
                <>
                  <form onSubmit={handleSubmitBuscar}>
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label className="fw-bold mx-1 form-label">
                          ID o nombre exacto de la campaña: &nbsp;<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          placeholder="Ingresa ID o nombre exacto de la campaña"
                          value={camp}
                          onChange={(e) => setCamp(e.target.value)}
                        />
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label
                          className="fw-bold mx-1 form-label"
                          style={{ visibility: "hidden" }}
                        >
                          Buscar: &nbsp;<span className="text-danger">*</span>
                        </label>
                        <button
                          aria-label="Buscar"
                          className="btnGreen w-100"
                          type="submit"
                        >
                          Buscar
                        </button>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                        <label
                          className="fw-bold mx-1 form-label"
                          style={{ visibility: "hidden" }}
                        >
                          Volver atrás: &nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <button
                          aria-label="Cancelar"
                          className="cancel w-100"
                          onClick={() => {
                            handleVolver();
                          }}
                        >
                          Volver atrás
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>

            <div className="col-12 mt-5">
              {existenDatos && (
                <>
                  {busqueda == 1 && producto && (
                    <>
                      <form onSubmit={handleEditarMarcaje}>
                        <div className="row">
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Id del producto:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={producto.id}
                              readOnly={true}
                              disabled={true}
                              // onChange={(e) => setProductoNombre(e.target.value)}
                            />
                          </div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Nombre del producto:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={producto.nombre}
                              readOnly={true}
                              disabled={true}
                              // onChange={(e) => setProductoNombre(e.target.value)}
                            />
                          </div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              SKU:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={producto.sku}
                              readOnly={true}
                              disabled={true}
                              // onChange={(e) => setProductoNombre(e.target.value)}
                            />
                          </div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Slug:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={producto.slug}
                              readOnly={true}
                              disabled={true}
                              // onChange={(e) => setProductoNombre(e.target.value)}
                            />
                          </div>
                        </div>
                        <>
                          <div className="row">
                            <h2 className="regular">SEO</h2>
                          </div>

                          <div className="row">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                Meta title&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeTitle}
                                required
                                onChange={(e) =>
                                  handleTitleChange(e.target.value)
                                }
                              />
                              <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                                {titleCount}/156 caracteres
                              </small>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                Meta Description&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeDescription}
                                required
                                onChange={(e) =>
                                  handleDescriptionChange(e.target.value)
                                }
                              />
                              <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                                {descriptionCount}/156 caracteres
                              </small>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                h1&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeH1}
                                required
                                onChange={(e) => setMarcajeH1(e.target.value)}
                              />
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                h2&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeH2}
                                required
                                onChange={(e) => setMarcajeH2(e.target.value)}
                              />
                            </div>
                          </div>
                        </>

                        <div className="row">
                          <h2 className="regular">Fotos</h2>
                        </div>
                        {producto?.fotos?.map((foto, index) => (
                          <div className="row" key={index}>
                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mb-4">
                              <img
                                src={`https://dreamtec.cl${foto.ruta}`}
                                width={150}
                                height={100}
                                className="img-fluid"
                                alt={`Foto ${index + 1}`}
                              />
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                              <label className="fw-bold mx-1 form-label">
                                Alt foto {index + 1}:
                              </label>
                              <input
                                className="form-control"
                                placeholder={`Alt para foto ${index + 1}`}
                                type="text"
                                value={alt[index] || ""}
                                required
                                onChange={(e) => {
                                  const newAlt = [...alt]; // Copia del array actual
                                  const altValue = e.target.value;

                                  // Validar HTML en tiempo real
                                  if (/<\/?[a-z][\s\S]*>/i.test(altValue)) {
                                    setMensaje(
                                      `El campo Alt de la imagen ${
                                        index + 1
                                      } contiene HTML-rojo`
                                    );
                                    setTipo(2);
                                    return;
                                  }

                                  newAlt[index] = altValue; // Actualiza el valor del índice correspondiente
                                  setAlt(newAlt); // Actualiza el estado
                                }}
                              />
                            </div>
                          </div>
                        ))}

                        <div className="row">
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                            <button
                              aria-label="Guardar"
                              className="btnGreen w-100"
                              type="submit"
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                  {busqueda == 2 && categoriaPadre && (
                    <>
                      <form onSubmit={handleEditarMarcaje}>
                        <div className="row">
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Id de la categoría:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={categoriaPadre.id}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Nombre de la categoría:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={categoriaPadre.nombre}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                        </div>

                        <>
                          <div className="row">
                            <h2 className="regular">SEO</h2>
                          </div>

                          <div className="row">
                            <>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                Meta title&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeTitle}
                                required
                                onChange={(e) =>
                                  handleTitleChange(e.target.value)
                                }
                              />
                              <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                                {titleCount}/156 caracteres
                              </small>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                Meta Description&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeDescription}
                                required
                                onChange={(e) =>
                                  handleDescriptionChange(e.target.value)
                                }
                              />
                              <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                                {descriptionCount}/156 caracteres
                              </small>
                            </div>
                            </>
                          </div>
                        </>

                        <div className="row">
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                            <button
                              aria-label="Guardar"
                              className="btnGreen w-100"
                              type="submit"
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                  {busqueda == 3 && categoriaHija && (
                    <>
                      <form onSubmit={handleEditarMarcaje}>
                        <div className="row">
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Id de la categoría:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={categoriaHija.id}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Nombre de la categoría:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={categoriaHija.nombre}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                        </div>

                        <>
                          <div className="row">
                            <h2 className="regular">SEO</h2>
                          </div>

                          <div className="row">
                            <>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                Meta title&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeTitle}
                                required
                                onChange={(e) =>
                                  handleTitleChange(e.target.value)
                                }
                              />
                              <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                                {titleCount}/156 caracteres
                              </small>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                Meta Description&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeDescription}
                                required
                                onChange={(e) =>
                                  handleDescriptionChange(e.target.value)
                                }
                              />
                              <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                                {descriptionCount}/156 caracteres
                              </small>
                            </div>
                            </>
                          </div>
                        </>

                        <div className="row">
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                            <button
                              aria-label="Guardar"
                              className="btnGreen w-100"
                              type="submit"
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                  {busqueda == 4 && marca && (
                    <>
                      <form onSubmit={handleEditarMarcaje}>
                        <div className="row">
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Id de la marca:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={marca.id}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Nombre de la marca:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={marca.nombre}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                        </div>

                   

                        <div className="row">
                          <h2 className="regular">SEO</h2>
                        </div>

                        <div className="row">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                Meta title&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeTitle}
                                required
                                onChange={(e) =>
                                  handleTitleChange(e.target.value)
                                }
                              />
                              <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                                {titleCount}/156 caracteres
                              </small>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                Meta Description&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeDescription}
                                required
                                onChange={(e) =>
                                  handleDescriptionChange(e.target.value)
                                }
                              />
                              <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                                {descriptionCount}/156 caracteres
                              </small>
                            </div>
                        </div>

                        <div className="row">
                          <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mb-4">
                            <img
                              src={`${
                                marca.foto
                                  ? `https://dreamtec.cl${marca.foto}`
                                  : "https://dreamtec.cl:3000/uploads/static/media/cargando.f9a2c61c6f939aa2a980.gif"
                              }`}
                              width={150}
                              height={100}
                              className="img-fluid"
                              alt="Imagen de la marca"
                            ></img>
                          </div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                            <label className="fw-bold mx-1 form-label">
                              Alt foto:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={altMarca}
                              required
                              onChange={(e) => setAltMarca(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                            <button
                              aria-label="Guardar"
                              className="btnGreen w-100"
                              type="submit"
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}

                  {busqueda == 5 && campana && (
                    <>
                      <form onSubmit={handleEditarMarcaje}>
                        <div className="row">
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Id de la campaña:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={campana.id}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              Nombre de la campaña:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={campana.nombre}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                        </div>

                   

                        <div className="row">
                          <h2 className="regular">SEO</h2>
                        </div>

                        <div className="row">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                Meta title&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeTitle}
                                required
                                onChange={(e) =>
                                  handleTitleChange(e.target.value)
                                }
                              />
                              <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                                {titleCount}/156 caracteres
                              </small>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                              <label className="fw-bold mx-1 form-label">
                                Meta Description&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="text"
                                value={marcajeDescription}
                                required
                                onChange={(e) =>
                                  handleDescriptionChange(e.target.value)
                                }
                              />
                              <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                                {descriptionCount}/156 caracteres
                              </small>
                            </div>
                        </div>

                        <div className="row">
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <img
                              src={`${
                                campana.foto
                                  ? `${process.env.REACT_APP_FRONT_URL}${campana.foto}`
                                  : "https://dreamtec.cl:3000/uploads/static/media/cargando.f9a2c61c6f939aa2a980.gif"
                              }`}
                              width={500}
                              height={100}
                              className="img-fluid mt-3"
                              alt="Imagen de la marca"
                            ></img>
                          </div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                            <label className="fw-bold mx-1 form-label">
                              Alt foto:
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={altCampana}
                              required
                              onChange={(e) => setAltCampana(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                            <label className="fw-bold mx-1 form-label">
                              h1&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="text"
                              value={marcajeH1}
                              required
                              onChange={(e) => setMarcajeH1(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                            <button
                              aria-label="Guardar"
                              className="btnGreen w-100"
                              type="submit"
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marcaje;
