/**
 * Componente Layout:
 * 
 * acá se valida las URL del admin
 * 
 * @module ComponenteLayoutjsx
 */

import React from "react";
import { Header, Footer } from "../index";
import Routers from "../../routers/Routers";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();
  let url = location.pathname.toString().substring(location.pathname.toString().length, location.pathname.toString().length - 1)
  if (url == "/") {
    location.pathname = location.pathname.toString().substring(0, location.pathname.toString().length - 1)
  }

  const isAdmin =
    location.pathname === "/admin" ||
    location.pathname === "/admin/" ||
    location.pathname === "/admin/login" ||
    location.pathname === "/admin/dashboard" ||
    location.pathname === "/admin/tarifas" ||
    location.pathname === "/admin/usuarios" ||
    location.pathname === "/admin/puntoventa" ||
    location.pathname === "/admin/pedidos" ||
    location.pathname === "/admin/personal" ||
    location.pathname === "/admin/productos" ||
    location.pathname === "/admin/productos/agregar" ||
    location.pathname === "/admin/productos/actualizar" ||
    location.pathname === "/admin/banners" ||
    location.pathname === "/admin/grandesmarcas" ||
    location.pathname === "/admin/pedidosbodegaweb" ||
    location.pathname === "/admin/bannercategorias" ||
    location.pathname === "/admin/pedidoschofer" ||
    location.pathname === "/admin/personaladmin" ||
    location.pathname === "/admin/marcas" ||
    location.pathname === "/admin/categorias" ||
    location.pathname === "/admin/pedidosasignados" ||
    location.pathname === "/admin/pedidosbodegapdv" ||
    location.pathname === "/admin/pedidoschoferpdv" ||
    location.pathname === "/admin/transacciones" ||
    location.pathname === "/mantencion"||
    location.pathname === "/admin/marcaje"||
    location.pathname === "/admin/marcaje-banners"||
    location.pathname === '/admin/marcaje-banner-categoria' ||
    location.pathname === '/admin/campana' ||
    location.pathname == '/admin/editar-campana'

  return (
    <>
      {!isAdmin && <Header />}
      <div>
        <Routers />
      </div>
      {!isAdmin && (location.pathname != '/industrial' && location.pathname != '/industrial/plotter' && location.pathname != '/industrial/maquinas-bordar' && location.pathname != '/industrial/impresora-textil' && location.pathname != '/industrial/contacto') && <Footer />}
    </>
  );
};

export default Layout;
