/**
 * Página Home:
 * 
 * acá se genera la vista para el Home del Sitio Web
 * 
 * @module PaginaHomejsx
 */




import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  Carousel,
  NovedadesCards,
  EmpresasCard,
  SwiperCategorias,
  GamerZoneCards,
  HomeOfficeCards,
  SmartHomeCards,
} from "../components/index";
import "./home.css";

import { useDispatch, useSelector } from "react-redux";
import { setTipoEnvio } from "../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../redux/fechaEntregaSlice";
import { setComunaSelected } from "../redux/comunaSelectedSlice";
import { setLomasvendido } from "../redux/LoMasVendSlicer";
//import Mensaje from "../components/Modales/Mensaje";



const Home = () => {

  /**
   * @constant
   * @name userAlready
   * @type {Array}
   * 
   * */
  const [userAlready] = useState(sessionStorage.getItem("IDusuario"));

  const dispatch = useDispatch();

  /**
 * @constant
 * @name tipoEnvio
 * @type {number}
 * 
 * */
  const tipoEnvio = useSelector((state) => state.tipoEnvio);

  /**
  * @constant
  * @name bannerSuperiores
  * @type {Array}
  * 
  * */
  const [bannerSuperiores, setBannerSuperiores] = useState("");

  /**
   * @constant
   * @name bannerInferiores
   * @type {Array}
   * 
   * */
  const [bannerInferiores, setBannerInferiores] = useState("");

  /**
  * @constant
  * @name banner600x500
  * @type {Array}
  * 
  * */
  const [banner600x500, setBanner600x500] = useState("");

  /**
 * @constant
 * @name banner600_200
 * @type {Array}
 * 
 * */
  const [banner600_200, setBanner600_200] = useState("");

  /**
  * @constant
  * @name banner300_200
  * @type {Array}
  * 
  * */
  const [banner300_200, setBanner300_200] = useState("");

  /**
   * @constant
   * @name banner300_400
   * @type {Array}
   * 
   * */
  const [banner300_400, setBanner300_400] = useState("");

  /**
 * @constant
 * @name grandesMarcas
 * @type {Array}
 * 
 * */
  const [grandesMarcas, setGrandesMarcas] = useState("");

  /**
 * @constant
 * @name mensaje
 * @type {string}
 * 
 * */
  const [mensaje, setMensaje] = useState("");
  const [bannerEstaticos, setBannerEstaticos] = useState("");


  /**
   * @function
   * Petición HTTP que trae las categorias de los banners que se mostraran en el home
   * @returns {Array}
   * 
   */
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BACK_URL + `/api/categoria_banner/get_categorias_banners`
      )
      .then((response) => {
        setBannerSuperiores(response.data.banners_superior);
        setBannerInferiores(response.data.banner_inferior);
        setBanner600x500(response.data.banners_600_500);
        setBanner600_200(response.data.banners_600_200);
        setBanner300_200(response.data.banners_300_200);
        setBanner300_400(response.data.banners_300_400);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BACK_URL + `/api/banners/get_banner_estaticos`
      )
      .then((response) => {
        setBannerEstaticos(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /**
  * @function
  * Petición HTTP que trae las grandes marcas
  * @returns {Array}
  * 
  */
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/listar_grandes_marcas`)
      .then((response) => {
        setGrandesMarcas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  /**
  * @function
  * Petición HTTP que trae la dirección ingresada por el usuario anteriormente, 
  * validando que inicio sesión y el tipo de envio sea distinto de 4 (Retiro en tienda)
  * @param {string} userAlready Id del usuario
  * @param {number} tipoEnvio Tipo de envio seleccionado anteriormente
  *@returns {Array}
  * 
  */
  useEffect(() => {
    if (userAlready && tipoEnvio !== 4) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/direccion/get_direccion/${userAlready}`
        )
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direcicon.codigo_envio));
          dispatch(setFechaEntrega(response.data.direcicon.fecha));
          dispatch(setComunaSelected(response.data.direcicon.nombre_comuna));


        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tipoEnvio]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={process.env.REACT_APP_FRONT_URL} />
        <title>Dreamtec.cl | La tecnología que sueñas</title>
        <meta name="description" content={`Compra en Dreamtec.cl. Tecnología para Gamers, Oficina y Hogar. Encuentra los mejores precios en tecnología y productos exclusivos. Envíos a todo Chile.`} />
        <meta property="og:title" content={"Dreamtec"} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={process.env.REACT_APP_FRONT_URL} />
        <meta property="og:image" content="https://dreamtec.cl:3000/uploads/ico/logo192.ico" />
      </Helmet>
      <Carousel />



      <div className="container my-5 d-none d-md-none d-lg-block">
        {bannerEstaticos != "" &&
          <div className="row mb-5">
            <div className="col-xs-12">
              <img className="img-fluid" src={process.env.REACT_APP_FRONT_URL + bannerEstaticos[0].url} alt={`${bannerEstaticos[0].alt}`} />
            </div>
          </div>
        }

        <div className="row">
          {/*Llega hoy*/}
          <div className="col-md-3 col-xs-6 col-sm-6">
            <div className="row">
              <div className="col-md-4 col-xs-2">
                <img loading="eager" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/home/icono-caract-a.png"} className="img-fluid icoM" alt="Logo llega hoy" title="Logo llega hoy" />
              </div>
              <div className="col-md-8 col-xs-10">
                <span className="fs-12 fw-bold">LLEGA HOY</span>
                <p className="fs-12 mb-0">
                  Para compras en Santiago antes de las 12 hrs.
                </p>
              </div>
            </div>
          </div>

          {/*Llega garantía*/}
          <div className="col-md-3 col-xs-6 col-sm-6">
            <div className="row">
              <div className="col-md-4">
                <img loading="eager" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/home/icono-caract-b.png"} className="img-fluid icoM" alt="Logo garantia anual" title="Logo garantia anual" />
              </div>
              <div className="col-md-8">
                <span className="fs-12 fw-bold">GARANTIA ANUAL</span>
                <p className="fs-12 mb-0">
                  Todos nuestros productos cuentan con garantía por 1 año.
                </p>
              </div>
            </div>
          </div>

          {/*Llega cuotas*/}
          <div className="col-md-3 col-xs-6 col-sm-6">
            <div className="row">
              <div className="col-md-4">
                <img loading="eager" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/home/icono-caract-c.png"} className="img-fluid icoM" alt="logo sin interés" title="logo sin interés" />
              </div>
              <div className="col-md-8">
                <span className="fs-12 fw-bold">CUOTAS SIN INTERÉS</span>
                <p className="fs-12 mb-0">
                  Paga con tarjeta de crédito hasta en 6 cuotas sin interés.
                </p>
              </div>
            </div>
          </div>

          {/*Llega selllados*/}
          <div className="col-md-3 col-xs-6 col-sm-6">
            <div className="row">
              <div className="col-md-4">
                <img loading="eager" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/home/icono-caract-d.png"} className="img-fluid icoM" alt="logo productos sellados" title="logo productos sellados" />
              </div>
              <div className="col-md-8">
                <span className="fs-12 fw-bold">PRODUCTOS SELLADOS</span>
                <p className="fs-12 mb-0">
                  Productos totalmente nuevos y sellados.
                </p>
              </div>
            </div>
          </div>
        </div>{/*row*/}
      </div>

      {/*movil*/}
      <div className="container my-5 d-block d-md-block d-lg-none">
        {bannerEstaticos != "" &&
          <div className="row mb-5">
            <div className="col-xs-12">
              <img className="img-fluid" src={process.env.REACT_APP_FRONT_URL + bannerEstaticos[0].url} alt={bannerEstaticos[0].alt} />
            </div>
          </div>
        }

        <div className="row">
          <div className="col-xs-2 col-2 m-auto">
            <img loading="eager" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/home/icono-caract-a.png"} className="img icoMM" alt="Logo llega hoy" title="Logo llega hoy" />
          </div>
          <div className="col-xs-10 col-9 m-auto">
            <span className="fs-12 fw-bold">LLEGA HOY</span><br />
            <p className="fs-12 mb-0">
              Para compras en Santiago antes de las 17 hrs.
            </p>
          </div>

          <div className="col-xs-2 col-2 m-auto">
            <img loading="eager" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/home/icono-caract-b.png"} className="img-fluid icoMM" alt="Logo garantia anual" title="Logo garantia anual" />
          </div>
          <div className="col-xs-10 col-9 m-auto">
            <span className="fs-12 fw-bold">GARANTIA ANUAL</span>
            <p className="fs-12 mb-0">
              Todos nuestros productos cuentan con garantía por 1 año.
            </p>
          </div>

          <div className="col-xs-2 col-2 m-auto">
            <img loading="eager" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/home/icono-caract-c.png"} className="img-fluid icoMM" alt="logo sin interés" title="logo sin interés" />
          </div>
          <div className="col-xs-10 col-9 m-auto">
            <span className="fs-12 fw-bold">CUOTAS SIN INTERÉS</span>
            <p className="fs-12 mb-0">
              Paga con tarjeta de crédito hasta en 6 cuotas sin interés.
            </p>
          </div>

          <div className="col-xs-2 col-2 m-auto">
            <img loading="eager" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/home/icono-caract-d.png"} className="img-fluid icoMM" alt="logo productos sellados" title="logo productos sellados" />
          </div>
          <div className="col-xs-10 col-9 m-auto">
            <span className="fs-12 fw-bold">PRODUCTOS SELLADOS</span>
            <p className="fs-12 mb-0">
              Productos totalmente nuevos y sellados.
            </p>
          </div>
        </div>{/*row*/}
      </div>



      {/* Banners Superiores */}
      <div className="container">
        <div className="row">
          {bannerSuperiores.length > 0 &&
            bannerSuperiores.map((banner, index) => (

              <div
                key={index}
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-sm-12 mb-3 text-center"
                onClick={() => location.href = `/${banner.categoria_padre_nombre}/${banner.hija}`}
                type="button"
              >
                <img
                  className="img-fluid"
                  src={banner.foto != '' ? process.env.REACT_APP_FRONT_URL + `/${banner.foto}` : process.env.REACT_APP_FRONT_URL + "/uploads/assets/home/banner_320x230.png"}
                  alt={banner.alt}
                  title={"categoria " + banner.hija}
                  loading="eager"
                />
              </div>
            ))}
        </div>
      </div>

      {/* Productos destacados */}
      <div className="container my-4">
        <div className="row">
          <div className="col-md-6 text-start">
            <h1 className="col-12 fw-bold fs-6 text-turq px-0 mb-2">
              PRODUCTOS DESTACADOS
            </h1>
          </div>
          <div className="col-md-6 text-end">
            <button type="button" aria-label="Lo más vendido" onClick={(e) => { dispatch(setLomasvendido(true)) }}
              className="bg-white fs-14 text-verde rounded-3 border-1 py-1 px-2 btn-turq-hover btn-sombra me-1">
              <i className="fa-solid fa-trophy"></i>&nbsp;
              Lo más vendido
            </button>
            <button
              type="button" aria-label="Novedades"
              onClick={(e) => { dispatch(setLomasvendido(false)) }}
              className="bg-white fs-14 text-turq rounded-3 border-1 py-1 px-2 btn-turq-hover btn-sombra"
            >
              <i className="fa-regular fa-star"></i>&nbsp;
              Novedades
            </button>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <NovedadesCards />
        </div>
      </div>



      {/* Banners 300 x 400 */}
      <div className="container mb-4">
        <div className="row">
          {/* Version XXL */}
          <div className="col-sm-12 col-md-3 mt-3 d-xs-none d-sm-none d-md-none d-lg-none d-xl-none d-xxl-block">
            {banner300_400 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner300_400[0].categoria_padre_nombre}/${banner300_400[0].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner300_400[0]?.foto}`}
                alt={banner300_400[0].alt}
                title={"categoria " + banner300_400[0]?.hija}
                loading="lazy"
              />
            ) : (null)}
          </div>
          <div className="col-sm-12 col-md-3 mt-3 d-xs-none d-sm-none d-md-none d-lg-none d-xl-none d-xxl-block">
            {banner300_400 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner300_400[1].categoria_padre_nombre}/${banner300_400[1].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner300_400[1]?.foto}`}
                alt={banner300_400[1].alt}
                title={"categoria " + banner300_400[1].hija}
                loading="lazy"
              />
            ) : (null)}
          </div>

          {/* Version XL */}
          <div className="col-sm-12 col-md-3 mt-3 d-xs-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-none">
            {banner300_400 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner300_400[0].categoria_padre_nombre}/${banner300_400[0].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner300_400[0]?.foto}`}
                alt={banner300_400[0].alt}
                title={"categoria " + banner300_400[0].hija}
                loading="lazy"
                style={{ height: "243px" }}
              />
            ) : (null)}
          </div>
          <div className="col-sm-12 col-md-3 mt-3 d-xs-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-none">
            {banner300_400 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner300_400[1].categoria_padre_nombre}/${banner300_400[1].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner300_400[1]?.foto}`}
                alt={banner300_400[1].alt}
                title={"categoria " + banner300_400[1].hija}
                loading="lazy"
                style={{ height: "243px" }}
              />
            ) : (null)}
          </div>

          {/* Version LG */}
          <div className="col-sm-12 col-md-3 mt-3 d-xs-none d-sm-none d-md-none d-lg-block d-xl-none d-xxl-none">
            {banner300_400 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner300_400[0].categoria_padre_nombre}/${banner300_400[0].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner300_400[0]?.foto}`}
                alt={banner300_400[0].alt}
                title={"categoria " + banner300_400[0].hija}
                loading="lazy"
                style={{ height: "205px" }}
              />
            ) : (null)}
          </div>
          <div className="col-sm-12 col-md-3 mt-3 d-xs-none d-sm-none d-md-none d-lg-block d-xl-none d-xxl-none">
            {banner300_400 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner300_400[1].categoria_padre_nombre}/${banner300_400[1].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner300_400[1]?.foto}`}
                alt={banner300_400[1].alt}
                title={"categoria " + banner300_400[1].hija}
                loading="lazy"
                style={{ height: "205px" }}
              />
            ) : (null)}
          </div>

          {/* Version MD */}
          <div className="col-sm-12 col-md-3 mt-3 d-xs-none d-sm-none d-md-block d-lg-none d-xl-none d-xxl-none">
            {banner300_400 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner300_400[0].categoria_padre_nombre}/${banner300_400[0].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner300_400[0]?.foto}`}
                alt={banner300_400[0].alt}
                title={"categoria " + banner300_400[0].hija}
                loading="lazy"
                style={{ height: "154px" }}
              />
            ) : (null)}
          </div>
          <div className="col-sm-12 col-md-3 mt-3 d-xs-none d-sm-none d-md-block d-lg-none d-xl-none d-xxl-none">
            {banner300_400 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner300_400[1].categoria_padre_nombre}/${banner300_400[1].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner300_400[1]?.foto}`}
                alt={banner300_400[1].alt}
                title={"categoria " + banner300_400[1].hija}
                loading="lazy"
                style={{ height: "154px" }}
              />
            ) : (null)}
          </div>

          {/* Resto de versiones */}
          <div className="col-sm-12 col-md-3 mt-3 d-xs-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
            {banner300_400 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner300_400[0].categoria_padre_nombre}/${banner300_400[0].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner300_400[0]?.foto}`}
                alt={banner300_400[0].alt}
                title={"categoria " + banner300_400[0].hija}
                loading="lazy"
              />
            ) : (null)}
          </div>
          <div className="col-sm-12 col-md-3 mt-3 d-xs-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
            {banner300_400 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner300_400[1].categoria_padre_nombre}/${banner300_400[1].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner300_400[1]?.foto}`}
                alt={banner300_400[1].alt}
                title={"categoria " + banner300_400[1].hija}
                loading="lazy"
              />
            ) : (null)}
          </div>


          <div className="col-md-6 col-sm-12 mt-3">
            {banner600_200 ? (
              <img
                type="button"
                className="img-fluid"
                onClick={() => location.href = `/${banner600_200[0].categoria_padre_nombre}/${banner600_200[0].hija}`}
                src={process.env.REACT_APP_FRONT_URL + `/${banner600_200[0]?.foto}`}
                alt={banner600_200[0].alt}
                title={"categoria " + banner600_200[0].hija}
                loading="lazy"
              />
            ) : (null)}
            <div className="row mt-3">
              <div className="col-md-6 col-sm-12 border-white mb-3">
                {banner300_200 ? (
                  <img
                    src={process.env.REACT_APP_FRONT_URL + `/${banner300_200[0]?.foto}`}
                    alt={banner300_200[0].alt}
                    title={"categoria " + banner300_200[0].hija}
                    loading="lazy"
                    type="button"
                    className="img-fluid"
                    onClick={() => location.href = `/${banner300_200[0].categoria_padre_nombre}/${banner300_200[0].hija}`}
                    style={{ width: "515px" }}
                  />
                ) : (null)}
              </div>
              <div className="col-md-6 col-sm-12 border-white">
                {banner300_200 ? (
                  <img
                    src={process.env.REACT_APP_FRONT_URL + `/${banner300_200[1]?.foto}`}
                    alt={banner300_200[1].alt}
                    title={"categoria " + banner300_200[1].hija}
                    loading="lazy"
                    type="button"
                    className="img-fluid"
                    onClick={() => location.href = `/${banner300_200[1].categoria_padre_nombre}/${banner300_200[1].hija}`}
                    style={{ width: "515px" }}
                  />
                ) : (null)}
              </div>
            </div>
          </div>
        </div>
      </div>{/*container-fluid*/}

      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="fw-bold fs-6 text-turq">DREAMTEC EMPRESARIAL</h1>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="row row-productos-home" id="parent_gamer_zone">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3 ">
            <div className="row">


              <div className="col-6 d-none d-md-none d-lg-block">
                <EmpresasCard />
              </div>

              <div
                type="button"
                onClick={() => location.href = `/${banner600x500[0].categoria_padre_nombre}/${banner600x500[0].hija}`}
                className="col-6 d-none d-md-none d-lg-block"
              >
                {banner600x500 ? (
                  <img
                    className="img-fluid"
                    src={process.env.REACT_APP_FRONT_URL + `/${banner600x500[0]?.foto}`}
                    alt={banner600x500[0].alt}
                    title={"categoria " + banner600x500[0].hija}
                    loading="lazy"
                  />
                ) : (null)}
              </div>

              <div className="col-12 d-block d-md-block d-lg-none">
                <EmpresasCard />
              </div>
              <div
                type="button"
                onClick={() => location.href = `/${banner600x500[0].categoria_padre_nombre}/${banner600x500[0].hija}`}
                className="col-12 d-block d-md-block d-lg-none"
              >
                {banner600x500 ? (
                  <img
                    className="img-fluid"
                    src={process.env.REACT_APP_FRONT_URL + `/${banner600x500[0]?.foto}`}
                    alt={banner600x500[0].alt}
                    title={"categoria " + banner600x500[0].hija}
                    loading="lazy"
                  />
                ) : (null)}
              </div>
            </div>

            <div
              type="button"
              className="row mt-3 mb-3"
              onClick={() => location.href = `/${bannerInferiores[0].categoria_padre_nombre}/${bannerInferiores[0].hija}`}
            >

              <div className="col-md-12 text-center d-none d-md-none d-lg-block">
                {bannerInferiores ? (
                  <img
                    className="img-fluid"
                    src={process.env.REACT_APP_FRONT_URL + `/${bannerInferiores[0]?.foto}`}
                    alt={bannerInferiores[0].alt}
                    title={"categoria " + bannerInferiores[0].hija}
                    loading="lazy"
                  />
                ) : (null)}
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* LO ULTIMO EN CATEGORIAS PADRES */}
      <div className="container mt-5">
        {/* <div
          className="row row-productos-home"
          id="parent_gamer_zone"
        >
          <div className="col-lg-7 col-md-7 col-sm-11 col-11 mt-3">
            <div className="row justify-content-start mb-4 align-items-center">
              <div
                className="col-lg-5 col-md-11 col-sm-11 col-12 my-2 "
                data-categoria="home-office"
              >
                <span className="fw-bold fs-6 text-turq">
                  LO ULTIMO EN GAMER ZONE
                </span> 
              </div>
            </div>
          </div>
        </div> */}
      </div>


      <div className="container px-4">
        <a className="text-decoration-none" href="loultimoen/gamer-zone" aria-label="Gamer Zone">
          <div className="row justify-content-center text-center" id="gamer-zone-banner-fondo">
            <div className="col-11" style={{ marginTop: "100px" }}>
              <GamerZoneCards />
            </div>
          </div>
        </a>
      </div>

      {/* <div className="container">
        <div
          className="row row-productos-home"
          id="parent_gamer_zone"
        >
          <div className="col-lg-7 col-md-7 col-sm-11 col-11 mt-3">
            <div className="row justify-content-start mb-4 align-items-center">
              <div
                className="col-lg-5 col-md-11 col-sm-11 col-12 my-2 "
                data-categoria="home-office"
              >
                <span className="fw-bold fs-6 text-turq">
                  LO ULTIMO EN HOME OFFICE
                </span>
              </div> 
            </div>
          </div>
        </div>
      </div> */}


      <div className="container px-4">
        <a className="text-decoration-none" href="loultimoen/home-office" aria-label="Home Office">
          <div className="row justify-content-center text-center" id="home-office-banner-fondo">
            <div className="col-11" style={{ marginTop: "100px" }}>
              <HomeOfficeCards />
            </div>
          </div>
        </a>
      </div>

      {/* <div className="container">
        <div
          className="row row-productos-home"
          id="parent_gamer_zone"
        >
          <div className="col-lg-7 col-md-7 col-sm-11 col-11 mt-3">
            <div className="row justify-content-start mb-4 align-items-center">
              <div
                className="col-lg-5 col-md-11 col-sm-11 col-12 my-2 "
                data-categoria="home-office"
              >
                <span className="fw-bold fs-6 text-turq">
                  LO ULTIMO EN SMART HOME
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <div className="container px-4">
        <a className="text-decoration-none" href="loultimoen/smart-home" aria-label="Smart Home">
          <div className="row justify-content-center text-center" id="smart-home-banner-fondo">
            <div className="col-11" style={{ marginTop: "100px" }}>
              <SmartHomeCards />
            </div>
          </div>
        </a>
      </div>


      <div className="container my-5">
        <div className="row">
          {grandesMarcas.length > 0 &&
            grandesMarcas.map((marca, index) => (
              <div
                type="button"
                key={index}
                onClick={() => location.href = `/${marca.nombre_interno}`}
                className="col-lg-3 col-md-6 col-xs-12 my-2 text-center mb-5"
              >
                <img
                  className="img-fluid"
                  src={process.env.REACT_APP_FRONT_URL + `/${marca.foto}`}
                  alt={marca.alt}
                  title={marca.nombre_interno}
                  loading="lazy"
                />
              </div>
            ))}
        </div>
      </div>
      



      <div className="d-lg-none">
        <SwiperCategorias />
      </div>
    </>
  );
};

export default Home;
