/**
 * Página Marcas:
 * 
 * acá se muestran los productos por sus marcas
 * 
 * @module PaginaBrandPagejsx
 */

import { useState, useEffect, useRef } from "react";
import "./producpage.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

import axios from "axios";
import Slider from "react-slider";

import { useSelector, useDispatch } from "react-redux";
import { setBoolean } from "../redux/esBusquedaSlice";
import { setAddToCart } from "../redux/AddToCartSlice";
import { setCheckStock } from "../redux/checkStockSlice";
import { Categorias } from "../components";
import { useNavigate } from "react-router-dom";
import { setTipoEnvio } from "../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../redux/fechaEntregaSlice";
import { setComunaSelected } from "../redux/comunaSelectedSlice";
//import htmlReactParser from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';

const BrandPage = () => {
  axios.defaults.headers.common["Content-Type"] = "application/json";

  /**
* @constant
* @name tipoEnvio
* @type {number}
* 
* */


  const tipoEnvio = useSelector((state) => state.tipoEnvio);

  /**
* @constant
* @name userAlready
* @type {string}
* 
* */

  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );


  /**
* @constant
* @name productos
* @type {Array}
* 
* */
  const [productos, setProductos] = useState([]);

  /**
* @constant
* @name categoria
* @type {string}
* 
* */
  const [categoria, setCategoria] = useState("");

  /**
* @constant
* @name cPadre
* @type {string}
* 
* */

  const [cPadre, setCPadre] = useState("");

  /**
* @constant
* @name order
* @type {string}
* 
* */
  const [order, setOrder] = useState("");

  /**
* @constant
* @name currentPage
* @type {number}
* 
* */
  const [currentPage, setCurrentPage] = useState(1);

  /**
* @constant
* @name totalPages
* @type {number}
* 
* */
  const [totalPages, setTotalPages] = useState(0);

  /**
* @constant
* @name minRange
* @type {number}
* 
* */
  const [minRange, setMinRange] = useState(0);

  /**
* @constant
* @name maxRange
* @type {number}
* 
* */
  const [maxRange, setMaxRange] = useState(0);

  /**
* @constant
* @name marcas
* @type {Array}
* 
* */
  const [marcas, setMarcas] = useState([]);

  /**
* @constant
* @name fotoMarca
* @type {string}
* 
* */
  const [fotoMarca, setFotoMarca] = useState("");


  /**
* @constant
* @name marcaSeleccionada
* @type {string}
* 
*/

  const [marcaSeleccionada, setMarcaSeleccionada] = useState("");



  /**
* @constant
* @name msg
* @type {string}
* 
*/
  const [msg, setMsg] = useState("");

  /**
* @constant
* @name priceMinMax
* @type {Array}
* 
*/
  const priceMinMax = useRef([]);

  /**
* @constant
* @name categoriaPadre
* @type {string}
* 
*/
  const [categoriaPadre, setCategoriaPadre] = useState("");


  /**
* @constant
* @name valores
* @type {Array}
* 
*/
  const [valores, setValores] = useState([]);


  const categoriaCapitalizada = useRef("");


  /**
* @constant
* @name tipoCard
* @type {number}
* 
*/
  const [tipoCard, setTipoCard] = useState(1);


  /**
* @constant
* @name laMarca
* @type {string}
* 
*/
  const [laMarca, setLaMarca] = useState("");

  /**
* @constant
* @name isLoaded
* @type {boolean}
* 
*/
  const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();

  const idCarro = useSelector((state) => state.idCarro);
  const checkStock = useSelector((state) => state.checkStock);
  const dispatch = useDispatch();

  const addToCart = useSelector((state) => state.addToCart);
  const [stock, setStock] = useState("");
  const [showAgregado, setShowAgregado] = useState(false);
  const [marcaje, setMarcaje] = useState(null);

  const addProducto = (e) => {
    setStock(e);
    handleAddToCart(e.sku);
  };
  const mostrarAgregado = () => {
    handleShowAgregado();
  }
  const handleShowAgregado = () => setShowAgregado(true);
  const handleCloseAgregado = () => {
    setShowAgregado(false);
  }

  const irAPagar = () => {
    if (sessionStorage.accessTokenWeb)
      navigate("/paso2");
    else
      navigate("/paso1");
  };

  const addToCartGa4 = (sku, cantidad) => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/ga/agregarproductoga4?sku=${sku}&cantidad=${cantidad}`)
      .then((response) => {
        ReactGA.gtag("event", "add_to_cart", {
          currency: "CLP",
          value: response.data.value,
          items: [{
            item_id: response.data.sku,
            item_name: response.data.nombre,
            item_brand: response.data.marca,
            item_category: response.data.categoria_padre,
            item_category2: response.data.categoria_hija,
            price: response.data.precio,
            quantity: response.data.cantidad
          }]
        });
      })
      .catch((error) => {
        console.log(error)
      })
  };

  const handleAddToCart = (sku) => {
    axios
      .post(
        process.env.REACT_APP_BACK_URL + "/api/carrocompras/register_carrocompras",
        {
          usuario: userAlready ? userAlready : usuarioId,
          sku: sku,
          cantidad: 1,
        }
      )
      .then((response) => {
        sessionStorage.setItem("IDusuario", response.data.usuario);
        setUserAlready(sessionStorage.getItem("IDusuario"));
        dispatch(setAddToCart(addToCart + 1));
        dispatch(setCheckStock(response.data.productos[0].cantidad));
        mostrarAgregado();
        addToCartGa4(sku, 1)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
* @function
* Petición HTTP que trae la dirección ingresada por el usuario anteriormente, 
* validando que inicio sesión y el tipo de envio sea distinto de 4 (Retiro en tienda)
* @param {string} userAlready Id del usuario
* @param {number} tipoEnvio Tipo de envio seleccionado anteriormente
*@returns {Array}
* 
*/
  useEffect(() => {
    if (userAlready && tipoEnvio !== 4) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/direccion/get_direccion/${userAlready}`
        )
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direcicon.codigo_envio));
          dispatch(setFechaEntrega(response.data.direcicon.fecha));
          dispatch(setComunaSelected(response.data.direcicon.nombre_comuna));


        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tipoEnvio]);


  /**
 * @function
 * Metodo que redirecciona segun el orden establecido en los filtros
 * @param {string} order string con el orden seleccionado en el filtro
 * 
 */
  const handleCambioOrder = (orden) => {
    setOrder(orden);
    //navigate(`/${categoria}?page=${currentPage}&order=${orden}`);
  };

  /**
  * @function
  * Metodo que toma como parametro un string que se valida para ser usado en la petición HTTP
  * @param {string} texto string para validar
  * @returns {boolean}
  * 
  */
  const validaQuery = (texto) => {
    let validar = [`"`, `.`, `?`, `=`, `'`, `*`, `<`, `>`, `;`, `(`, `)`, `{`, `}`, `[`, `]`, `,`, `:`, '`'];
    if (texto != null) {
      for (let i = 0; i < validar.length; i++) {
        if (texto.includes(validar[i]))
          return false;
      }
      return true;
    }
    else
      return false;
  };


  const handleTipoCard = (valor) => {
    setTipoCard(valor);
  };

  const pathname = window.location.pathname;
  const match = pathname.split("/");
  if (match[1] != categoria) {
    const categoria = validaQuery(match[1]) ? match[1] : "";

    categoriaCapitalizada.current = categoria;

    while (categoriaCapitalizada.current.includes("-"))
      categoriaCapitalizada.current = categoriaCapitalizada.current.replace("-", " ");

    let separar = categoriaCapitalizada.current.split(" ");
    let cur = "";
    for (let i = 0; i < separar.length; i++)
      cur += separar[i].charAt(0).toUpperCase() + separar[i].slice(1).toLowerCase() + " ";
    categoriaCapitalizada.current = cur;
    setCategoria(categoria);
  }

  const handleValores = (vals) => {
    setValores([...vals]);
  };

  /**
* @function
* Petición HTTP que busca los productos por marca con los filtros
* @param {string} categoria categoria seleccionada
* @param {string} order orden de despliegue de los productos desc o asc
* @param {number} currentPage página actual
* @param {Array} valores arreglo que contiene los valores máximo y mínimo para el filtro por rango
* @returns {Array}
* 
*/
  const cargaDatos = () => {

    axios.get(
      process.env.REACT_APP_BACK_URL + `/get_productos_by_marcas?filter_marca=${categoria}&order=${order}&page=${currentPage}&precio_bajo=${valores[0]}&precio_alto=${valores[1]}`
    )
      .then((response) => {
        setIsLoaded(true);
        setProductos(response.data.results);
        setTotalPages(response.data.pages);
        setCPadre(categoriaPadre);
        setMarcas(response.data.marcas);
        setMinRange(response.data.precio_bajo);
        setMaxRange(response.data.precio_alto);
        if (response.data.mensaje != "" && response.data.mensaje != null)
          setMsg(response.data.mensaje);
        else
          setMsg("");
        let catCap = document.querySelector("#texto-categoria-capitalizada");
        if (catCap.textContent == "" || catCap.textContent == null)
          catCap.textContent = categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase();
        if (minRange != response.data.precio_bajo || maxRange != response.data.precio_alto) {
          priceMinMax.current = ([response.data.precio_bajo, response.data.precio_alto]);
        }

        axios.get(`${process.env.REACT_APP_BACK_URL}/api/admin/listar_marcas`)
          .then((response) => {
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].nombre == match[1])
                setLaMarca(response.data[i].nombre_mostrar);
            }
          })
          .catch((error) => {
            console.log(error);
          });

      })
      .catch((error) => {
        console.error(error);
      });

    axios.get(`${process.env.REACT_APP_BACK_URL}/api/marcas/listar_gran_marca_por_marca?marca=${categoria}`)
      .then((response) => {
        setFotoMarca(response.data[0].foto);
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch(setBoolean(false));
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const ubicacion = window.location.pathname.split("/");
    console.log(window.location.pathname);
    if (ubicacion.length > 2) {
      setCategoriaPadre(ubicacion[1]);
    }
    cargaDatos();
  }, [
    order,
    currentPage,
    categoria,
    idCarro,
    checkStock,
    minRange,
    maxRange,
    valores,
  ]);

  /**
* @function
* Metodo para limpiar los filtros
* 
*/
  const handleCleanFilters = () => {
    dispatch(setCheckStock(checkStock + 1));
    handleCambioLimites([minRange, maxRange]);
    location.reload();
  };

  const creaShort = (texto) => {
    let a = ['&aacute;', '&eacute;', '&iacute;', '&oacute;', '&uacute;', '&Aacute;', '&Eacute;', '&Iacute;', '&Oacute;', '&Uacute;', '&ntilde;', '&Ntilde;', '&nbsp;'];
    let b = ['á', 'é', 'í', 'ó', 'ú', 'Á', 'É', 'Í', 'Ó', 'Ú', 'ñ', 'Ñ', " "];
    texto = texto.replace(/<[^>]+>/g, "");
    for (let i = 0; i < a.length; i++)
      texto = texto.replaceAll(a[i], b[i]);
    texto = texto.trim();
    return texto;
  };

  /**
* @function
* Metodo para limpiar los filtros
* @param {string} marca marca seleccionada
* 
*/
  const handleMarcaSeleccionada = (marca) => {
    if (marca === marcaSeleccionada) {
      setMarcaSeleccionada("");

    } else {
      setMarcaSeleccionada(marca);

    }

    if (!marcaSeleccionada && !marca) {
    }
  };

  /**
* @function
* Metodo para marcar el submit y buscar los productos por marca
* 
*/
  const handleMarcaSubmit = () => {
    dispatch(setCheckStock(checkStock + 1));
    cargaDatos();
  };


  /**
 * @function
 * Metodo que asigna los limites en los inputs de rango
 * @param {Array} limites array que contiene el máximo y mínimo del filtro de precios
 * 
 */
  const handleCambioLimites = (limites) => {
    priceMinMax.current = (limites);
    document.querySelector("#rango_minimo_view").textContent = "$" + limites[0].toLocaleString("es-CL");
    document.querySelector("#rango_maximo_view").textContent = "$" + limites[1].toLocaleString("es-CL");
  };

  useEffect(() => {
    const urlPath = window.location.pathname;
    const segments = urlPath.split('/').filter(Boolean);
    const categoryas = segments[0];
    axios.get(
      process.env.REACT_APP_BACK_URL + `/api/marcaje/obtener_marcaje_marca?marca=${categoryas}`
    )
      .then((response) => {
        setMarcaje(response.data.marcaje);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>

      {marcaje && (
        <Helmet>
          <link rel="canonical" href={process.env.REACT_APP_FRONT_URL + "/" + match[1]} />
          {(currentPage != 1 || order) ? (
            <meta name="robots" content="NOINDEX,FOLLOW" />
          ) : (null)}
          {(marcaje.title && marcaje.title != "" && marcaje.title != null) ? (
            <title>{marcaje.title + " | Dreamtec"}</title>
          ):(
            <title>{"Dreamtec"}</title>
          )}
          <meta name="description" content={marcaje.description} />
          <meta property="og:description" content={marcaje.description} />
          <meta property="og:title" content={marcaje.title + " | Dreamtec"} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={process.env.REACT_APP_FRONT_URL + "/" + match[1]} />
          <meta property="og:image" content={process.env.REACT_APP_FRONT_URL + fotoMarca} />
        </Helmet>
      )}
      {/* <Helmet>
        <link rel="canonical" href={process.env.REACT_APP_FRONT_URL + "/" + match[1]} />
        {(currentPage != 1 || order) ? (
          <meta name="robots" content="NOINDEX,FOLLOW" />
        ) : (null)}
        <title>{categoriaCapitalizada.current + " | Dreamtec"}</title>
        <meta name="description" content={`Compra ${categoriaCapitalizada.current}en Dreamtec.cl. Tecnología para Gamers, Oficina y Hogar. Encuentra los mejores precios en tecnología y productos exclusivos. Envíos a todo Chile.`} />
        <meta property="og:title" content={categoriaCapitalizada.current + "| Dreamtec"} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={process.env.REACT_APP_FRONT_URL + "/" + match[1]} />
        <meta property="og:image" content={process.env.REACT_APP_FRONT_URL + fotoMarca} />
      </Helmet> */}

      <Modal show={showAgregado} onHide={handleCloseAgregado} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container ms-2 me-2" >
            {stock && (
              <>
                <div className="row justify-content-center text-center col-12 mb-4">
                  <div className="col-md-12">
                    <label htmlFor="" className="fw-bold" style={{ color: "#00bfd6", fontSize: "26px" }}>Has agregado &nbsp;</label>
                    <img
                      src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/icono-boton-carro-agregar.png"}
                      alt="imagen carrito"
                      title="imagen carrito"
                      className="imgcarrito bg-azul text-white rounded-pill px-2 py-1 border-0 fs-14"
                      style={{ transform: "translate(0%, -15%)" }}
                      loading="lazy"
                    />{" "}
                  </div>
                </div>

                <div className="row justify-content-center align-self-center">
                  <div className="col-md-6">
                    <img src={process.env.REACT_APP_FRONT_URL + stock.alt_foto_1} alt={stock.alt} title={stock.nombre} className="img-fluid" loading="lazy" />
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label htmlFor="" style={{ fontSize: "18px" }} className="fw-bold col-md-8 mt-3">{stock.nombre}</label>
                      <label htmlFor="" style={{ fontSize: "18px" }} className="col-md-4 mt-3">{"$" + stock.precio.toLocaleString("es-CL")}</label>
                    </div>
                  </div>
                </div>

                <hr style={{ height: "1px" }} />

                <div className="row col-md-12">
                  <div className="col-md-6">
                    <button
                      aria-label="Verificar categoria"
                      className="btnCelesteDreamtec mt-3 px-3 col-md-6 col-xs-12 w-100"
                      type="submit"
                      onClick={handleCloseAgregado}
                    >
                      Seguir comprando
                    </button>
                  </div>
                  <div className="col-md-6 mb-4">
                    <button
                      aria-label="Verificar categoria"
                      className="btnGreen mt-3 px-3 col-md-6 col-xs-12 w-100"
                      type="submit"
                      onClick={() => { irAPagar() }}
                    >
                      Ir a pagar
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

        </Modal.Body>
      </Modal>


      {isLoaded ? (

        <>

          <div className="container mt-5">
            <div className="row">
              {/* Version Web */}
              <div className="col-md-3 d-xs-none d-sm-none d-md-block mb-3">
                <div className="row border bg-gris-c mx-0 px-0">
                  <div className="row justify-content-center mx-0 px-0">
                    <button
                      onClick={(e) => {
                        handleCleanFilters();
                      }}
                      className="btn-borde-turq rounded-pill py-1 px-2 fw-bold col-md-10 col-xl-8 mt-3"
                    >
                      Limpiar filtros
                    </button>
                  </div>

                  {maxRange > 1 ? (
                    <>
                      <div className="row mt-4 mb-2 mx-0 px-0">
                        <label
                          className="col-12 fw-bold mb-3 text-center"
                          style={{ fontSize: "18px" }}
                        >
                          FILTRAR POR RANGO DE PRECIO
                        </label>
                      </div>

                      <div className="row mb-3 justify-content-center mx-0 px-0">
                        <div className="row col-12 px-4 mx-0 px-0">
                          <Slider className="slider-precios mb-4"
                            onChange={handleCambioLimites}
                            defaultValue={[minRange, maxRange]}
                            onAfterChange={handleValores}
                            value={valores}
                            pearling={true}
                            min={minRange}
                            max={maxRange}
                          />
                        </div>
                        <div className="row">
                          {/* Version XXL */}
                          <div className="col-xxl-6 justify-content-start d-xxl-block d-xs-none d-sm-none d-md-none">
                            <label
                              htmlFor="customRange1"
                              className="fs-12 text-dark fw-bold"
                            >
                              PRECIO MÍNIMO
                              <span
                                type="text"
                                name="min_price_view"
                                className="form-control fw-bold disabled_input w-80"
                                id="rango_minimo_view"
                              >
                                {priceMinMax.current[0]
                                  ? "$" + priceMinMax.current[0].toLocaleString("es-CL")
                                  : "$" + minRange.toLocaleString("es-CL")}
                              </span>
                            </label>
                          </div>

                          <div className="col-6 justify-content-end text-end d-xxl-block d-xs-none d-xs-none d-sm-none d-md-none">
                            <label
                              htmlFor="customRange2"
                              className="fs-12 text-dark fw-bold"
                            >
                              PRECIO MÁXIMO
                              <span
                                type="text"
                                name="max_price_view"
                                className="form-control fw-bold disabled_input w-80"
                                id="rango_maximo_view"
                              >
                                {priceMinMax.current[1]
                                  ? "$" + priceMinMax.current[1].toLocaleString("es-CL")
                                  : "$" + maxRange.toLocaleString("es-CL")}
                              </span>
                            </label>
                          </div>

                          {/* Version XL */}
                          <div className="col-xl-6 justify-content-start d-xxl-none d-xl-block d-xs-none d-sm-none d-md-none ps-0">
                            <label
                              htmlFor="customRange1"
                              className="fs-12 text-dark fw-bold col-12"
                            >
                              PRECIO MÍNIMO
                              <span
                                type="text"
                                name="min_price_view"
                                className="form-control fw-bold disabled_input w-100"
                                id="rango_minimo_view"
                              >
                                {priceMinMax.current[0]
                                  ? "$" + priceMinMax.current[0].toLocaleString("es-CL")
                                  : "$" + minRange.toLocaleString("es-CL")}
                              </span>
                            </label>
                          </div>

                          <div className="col-xl-6 justify-content-end text-end d-xxl-none d-xl-block d-xs-none d-sm-none d-md-none">
                            <label
                              htmlFor="customRange2"
                              className="fs-12 text-dark fw-bold"
                            >
                              PRECIO MÁXIMO
                              <span
                                type="text"
                                name="max_price_view"
                                className="form-control fw-bold disabled_input w-100"
                                id="rango_maximo_view"
                              >
                                {priceMinMax.current[1]
                                  ? "$" + priceMinMax.current[1].toLocaleString("es-CL")
                                  : "$" + maxRange.toLocaleString("es-CL")}
                              </span>
                            </label>
                          </div>

                          {/* Version LG */}
                          <div className="col-lg-12 justify-content-start d-xxl-none d-xl-none d-lg-block d-xs-none d-sm-none d-md-block">
                            <label
                              htmlFor="customRange1"
                              className="fs-12 text-dark fw-bold w-100 text-center"
                            >
                              PRECIO MÍNIMO
                              <span
                                type="text"
                                name="min_price_view"
                                className="form-control fw-bold disabled_input w-100"
                                id="rango_minimo_view"
                              >
                                {priceMinMax.current[0]
                                  ? "$" + priceMinMax.current[0].toLocaleString("es-CL")
                                  : "$" + minRange.toLocaleString("es-CL")}
                              </span>
                            </label>
                          </div>

                          <div className="col-lg-12 justify-content-end text-end d-xxl-none d-xl-none d-lg-block d-xs-none d-sm-none d-md-block">
                            <label
                              htmlFor="customRange2"
                              className="fs-12 text-dark fw-bold w-100 text-center"
                            >
                              PRECIO MÁXIMO
                              <span
                                type="text"
                                name="max_price_view"
                                className="form-control fw-bold disabled_input w-100 text-center"
                                id="rango_maximo_view"
                              >
                                {priceMinMax.current[1]
                                  ? "$" + priceMinMax.current[1].toLocaleString("es-CL")
                                  : "$" + maxRange.toLocaleString("es-CL")}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (null)}
                </div>

                <Categorias />
              </div>

              {/* Version Movil */}
              <div className="accordion d-md-none d-xs-block border border-0 mb-5" id="accordionExample">
                <div className="accordion-item border border-0">
                  <p className="accordion-header px-3">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <i className="fas fa-caret-right pe-1"></i>
                      Desplegar Filtros
                    </button>
                  </p>
                  <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body mt-0 pt-0">

                      <div className="col-xs-12 mb-3">
                        <div className="container border bg-gris-c">
                          <div className="row justify-content-center">
                            <button
                              onClick={(e) => {
                                handleCleanFilters();
                              }}
                              className="btn-borde-turq rounded-pill py-1 px-2 fw-bold col-8 mt-3"
                            >
                              Limpiar filtros
                            </button>
                          </div>

                          {maxRange > 0 ? (
                            <>
                              <div className="row mt-4 mb-2">
                                <label
                                  className="col-12 fw-bold mb-3 text-center"
                                  style={{ fontSize: "18px" }}
                                >
                                  FILTRAR POR RANGO DE PRECIO
                                </label>
                              </div>

                              <div className="row mb-3 justify-content-center">
                                <div className="row col-12 px-4">
                                  <Slider className="slider-precios mb-4"
                                    onChange={handleCambioLimites}
                                    defaultValue={[minRange, maxRange]}
                                    onAfterChange={handleValores}
                                    value={valores}
                                    pearling={true}
                                    min={minRange}
                                    max={maxRange}
                                  />
                                </div>
                                <div className="row col-12">
                                  <div className="col-6 justify-content-start text-start">
                                    <label
                                      htmlFor="customRange1"
                                      className="fs-12 text-dark fw-bold"
                                    >
                                      PRECIO MÍNIMO
                                      <span
                                        type="text"
                                        name="min_price_view"
                                        className="form-control fw-bold disabled_input w-80"
                                        id="rango_minimo_view"
                                      >
                                        {priceMinMax.current[0]
                                          ? "$" + priceMinMax.current[0].toLocaleString("es-CL")
                                          : "$" + minRange.toLocaleString("es-CL")}
                                      </span>
                                    </label>
                                  </div>

                                  <div className="col-6 justify-content-end text-end">
                                    <label
                                      htmlFor="customRange2"
                                      className="fs-12 text-dark fw-bold text-end"
                                    >
                                      PRECIO MÁXIMO
                                      <span
                                        type="text"
                                        name="max_price_view"
                                        className="form-control fw-bold disabled_input w-80"
                                        id="rango_maximo_view"
                                      >
                                        {priceMinMax.current[1]
                                          ? "$" + priceMinMax.current[1].toLocaleString("es-CL")
                                          : "$" + maxRange.toLocaleString("es-CL")}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (null)}
                        </div>
                        <Categorias />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-sm-12 col-md-8">
                {/*select*/}


                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                      <a
                        className="text-black"
                        href="/"
                        style={{ textDecoration: "none", width: "auto", padding: "0px", float: "left" }}
                      >
                        Inicio
                      </a>
                      <span className="text-black" style={{ width: "auto", padding: "0px", float: "left" }}>
                        <i className="fa-solid fa-angle-right px-2"></i>
                      </span>
                      <h1 className="text-turq fw-bold" id="texto-categoria-capitalizada" style={{ width: "auto", padding: "0px", fontSize: "17px", float: "left" }}>
                        {laMarca}
                      </h1>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 align-self-end ps-6">
                      <select
                        className="form-select select-buscar-desktop"
                        aria-label="Orden de productos"
                        onChange={(e) => handleCambioOrder(e.target.value)}
                      >
                        <option defaultValue="Seleccione una opción">
                          Seleccione una opción
                        </option>
                        <option value="nombre-asc">Nombre de A - Z</option>
                        <option value="nombre-desc">Nombre de Z - A</option>
                        <option value="precio-asc">Precios de Menor A Mayor</option>
                        <option value="precio-desc">Precios de Mayor A Menor</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 text-end pt-2">
                      <i className="fa fa-bars fs-4" role="button" onClick={() => { handleTipoCard(1) }}></i> &nbsp;
                      <i className="fa fa-th-large fs-4" role="button" onClick={() => { handleTipoCard(2) }}></i>
                    </div>
                  </div>
                </div>

                {/*fin select*/}

                <div className="row mt-5 mx-0">
                  {msg != "" && (
                    <div className="container d-xs-none row justify-content-center bg-grey mb-5">
                      <div className="row mt-3 mb-3 text-center">
                        <div className="col-12">
                          <i className="fas fa-exclamation-circle fs-2 text-turq"></i>
                        </div>
                        <div className="col-12">
                          <span className="text-turq fw-bold fs-5">
                            No encontramos productos con los filtros seleccionados en:{" "}
                            <span className="text-black">
                              {categoriaCapitalizada.current}{" "}
                            </span>
                          </span>
                        </div>
                      </div>{" "}
                      {(productos != [] && productos.length > 0) && (
                        <div className="row mt-1 mb-3 text-center">
                          <span className="small pb-0">
                            Puedes revisar estas alternativas que tenemos para tí
                          </span>
                        </div>
                      )}
                    </div>
                  )}


                  {productos != [] && productos.map((producto) => (
                    <>
                      {tipoCard === 1 && (
                        <>
                          {/* Version WEB LG de 999px hasta 1198px*/}
                          <div
                            className="card mb-2 col-btn-card col-btn-card-2 d-xs-none d-sm-none d-md-none d-lg-block"
                            key={producto.id}
                            style={{ height: "242px" }}
                          >
                            <div className="row justify-content-end mt-2">
                              <div className="text-end">
                                <span className="tag-prod-sku">SKU {producto.sku}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3 py-2 max-w-img text-center mt-3">
                                <a
                                  href={`/${producto.slug}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <img
                                    src={process.env.REACT_APP_FRONT_URL + `${producto.foto_1}`}
                                    alt={producto.alt_foto_1}
                                    title={producto.nombre}
                                    style={{ maxHeight: "100px", minHeight: "100px" }}
                                    className="img-fluid"
                                    loading="lazy"
                                  />
                                </a>
                              </div>
                              <div className="col-6 mb-3">
                                <div className="nombre-marca">
                                  <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                                  {producto.marca}
                                </div>
                                <a
                                  href={`/${producto.slug}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <h2 className="text-black mb-2 mt-0 nombre-tarj-prod fw-bold fs-6 h-auto">
                                    {producto.nombre}
                                  </h2>
                                  <div className="text-black" style={{ fontSize: "12px" }}>
                                    {producto.caracteristicas
                                      .split("</p>")
                                      .map((caracteristica, index) => (
                                        <p key={index} className="fz-12" style={{ marginBottom: "4px" }}>
                                          {creaShort(caracteristica) != "" && (
                                            creaShort(caracteristica)
                                          )}
                                        </p>
                                      ))}
                                  </div>
                                </a>
                              </div>

                              <div className="col-3 mb-3 mt-3">
                                <div className="card-text fs-15 text-turq fw-bold ">
                                  Precio Transferencia{" "}
                                  <p className="fs-5 fw-bold mb-1 text-black precio-prod-lista">
                                    {producto.precio_descuento.toLocaleString("es-CL", {
                                      style: "currency",
                                      currency: "CLP",
                                    })}
                                  </p>
                                </div>

                                <div className="card-text text-turq" style={{ fontSize: "12px" }}>
                                  Otros medios de pago{" "}
                                  <p className="fs-6 mb-2 precio-prod-lista" style={{ color: "grey", fontSize: "12px" }}>
                                    {producto.precio.toLocaleString("es-CL", {
                                      style: "currency",
                                      currency: "CLP",
                                    })}
                                  </p>
                                </div>

                                <div className="row justify-content-start">
                                  <button
                                    className="bg-turq text-white rounded-pill mbtn px-3 py-1 border-0 fs-14 btn-turq-hover btn-sombra col-11"
                                    onClick={() => { addProducto(producto) }}
                                  >
                                    Agregar al Carro
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Version Movil desde 0px hasta 998px*/}
                          <div className="col-12 d-xs-block d-lg-none">
                            <div
                              className="border border-dark-subtle mb-3 text-center px-2 pb-4 pt-1 rounded-3"
                              key={producto.id}
                            >
                              <div className="row justify-content-end mb-3 pe-3">
                                <div className="tag-prod-skunov mrc-5 text-center mt-1">
                                  <span>SKU {producto.sku}</span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-5 text-center">
                                  <a
                                    href={`/${producto.slug}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <img
                                      src={process.env.REACT_APP_FRONT_URL + `${producto.foto_1}`}
                                      alt={producto.alt_foto_1}
                                      title={producto.nombre}
                                      className="img-fluid mt-3"
                                      loading="lazy"
                                      style={{ maxHeight: "100px", minHeight: "100px" }}
                                    />
                                  </a>
                                </div>
                                <div className="col-7 text-start mb-2">
                                  <div className="nombre-marca">
                                    <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                                    {producto.marca}
                                  </div>
                                  <a
                                    href={`/${producto.slug}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <h2 className="text-black mb-2 mt-0 nombre-tarj-prod fw-bold fs-6 h-auto">
                                      {producto.nombre}
                                    </h2>
                                  </a>
                                  <div className="card-text fs-15 text-turq fw-bold ">
                                    Precio Transferencia{" "}
                                    <p className="fs-5 fw-bold mb-1 text-black precio-prod-lista">
                                      {producto.precio_descuento.toLocaleString("es-CL", {
                                        style: "currency",
                                        currency: "CLP",
                                      })}
                                    </p>
                                  </div>

                                  <div className="card-text text-turq" style={{ fontSize: "12px" }}>
                                    Otros medios de pago{" "}
                                    <p className="fs-6 mb-2 precio-prod-lista" style={{ color: "grey", fontSize: "12px" }}>
                                      {producto.precio.toLocaleString("es-CL", {
                                        style: "currency",
                                        currency: "CLP",
                                      })}
                                    </p>
                                  </div>
                                  <div className="row justify-content-start">
                                    <button
                                      className="bg-turq text-white rounded-pill mbtn px-3 py-1 border-0 fs-14 btn-turq-hover btn-sombra col-11"
                                      onClick={() => { addProducto(producto) }}
                                    >
                                      Agregar al Carro
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {tipoCard === 2 && (
                        <>
                          {/* Version Web */}
                          <div className="col-xl-3 col-lg-4 col-md-6 d-xs-none d-md-block">
                            <div
                              className="border border-dark-subtle mb-3 text-center px-2 pb-4 pt-1 rounded-3"
                              key={producto.id}
                            >
                              <div className="row justify-content-end mb-3">
                                <div className="tag-prod-skunov mrc-5 text-center mt-1 me-3" style={{ width: "50%" }}>
                                  <span>SKU {producto.sku}</span>
                                </div>
                              </div>
                              <div>
                                <a
                                  href={`/${producto.slug}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <img
                                    src={process.env.REACT_APP_FRONT_URL + `${producto.foto_1}`}
                                    alt={producto.alt_foto_1}
                                    title={producto.nombre}
                                    className="img-fluid"
                                    style={{ height: "150px" }}
                                    loading="lazy"
                                  />
                                </a>
                              </div>
                              <div className="row justify-content-center">
                                <div className="nombre-marca">
                                  <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                                  {producto.marca}
                                </div>
                              </div>

                              <div className="row text-center">
                                <a
                                  href={`/${producto.slug}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <h3 className="text-black mb-2 mt-0 nombre-tarj-prod fw-bold h-auto text-center cuarenta">
                                    {producto.nombre}
                                  </h3>
                                </a>
                                <div className="card-text text-turq fw-bold" style={{ fontSize: "15px" }}>
                                  Precio Transferencia{" "}
                                  <p className="fs-5 fw-bold mb-1 text-black precio-prod-lista">
                                    {producto.precio_descuento.toLocaleString("es-CL", {
                                      style: "currency",
                                      currency: "CLP",
                                    })}
                                  </p>
                                </div>

                                <div className="card-text text-turq" style={{ fontSize: "12px" }}>
                                  Otros medios de pago{" "}
                                  <p className="fs-6 mb-2 precio-prod-lista" style={{ color: "grey", fontSize: "12px" }}>
                                    {producto.precio.toLocaleString("es-CL", {
                                      style: "currency",
                                      currency: "CLP",
                                    })}
                                  </p>
                                </div>
                              </div>
                              <button
                                className="bg-turq text-white rounded-pill mbtn px-3 py-1 border-0 fs-14 btn-turq-hover btn-sombra col-11"
                                onClick={() => { addProducto(producto) }}
                              >
                                Agregar al Carro
                              </button>
                            </div>
                          </div>

                          {/* Version Movil */}
                          <div className="col-6 d-xs-block d-md-none">
                            <div
                              className="border border-dark-subtle mb-4 text-center px-2 pb-4 pt-1 rounded-3"
                              key={producto.id}
                            >
                              <div className="row justify-content-end mb-3">
                                <div className="tag-prod-skunov mrc-5 text-center mt-1 me-3" style={{ width: "50%" }}>
                                  <span>SKU {producto.sku}</span>
                                </div>
                              </div>
                              <div>
                                <a
                                  href={`/${producto.slug}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <img
                                    src={process.env.REACT_APP_FRONT_URL + `${producto.foto_1}`}
                                    alt={producto.alt_foto_1}
                                    title={producto.nombre}
                                    className="img-fluid"
                                    style={{ height: "150px" }}
                                    loading="lazy"
                                  />
                                </a>
                              </div>
                              <div className="row justify-content-center">
                                <div className="nombre-marca">
                                  <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                                  {producto.marca}
                                </div>
                              </div>

                              <div className="row text-center">
                                <a
                                  href={`/${producto.slug}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <h3 className="text-black mb-2 mt-0 nombre-tarj-prod fw-bold h-auto text-center">
                                    {producto.nombre}
                                  </h3>
                                </a>
                                <div className="card-text text-turq fw-bold" style={{ fontSize: "15px" }}>
                                  Precio Transferencia{" "}
                                  <p className="fs-5 fw-bold mb-1 text-black precio-prod-lista">
                                    {producto.precio_descuento.toLocaleString("es-CL", {
                                      style: "currency",
                                      currency: "CLP",
                                    })}
                                  </p>
                                </div>

                                <div className="card-text text-turq" style={{ fontSize: "12px" }}>
                                  Otros medios de pago{" "}
                                  <p className="fs-6 mb-2 precio-prod-lista" style={{ color: "grey", fontSize: "12px" }}>
                                    {producto.precio.toLocaleString("es-CL", {
                                      style: "currency",
                                      currency: "CLP",
                                    })}
                                  </p>
                                </div>
                              </div>
                              <button
                                className="bg-turq text-white rounded-pill mbtn px-3 py-1 border-0 fs-14 btn-turq-hover btn-sombra col-11"
                                onClick={() => { addProducto(producto) }}
                              >
                                Agregar al Carro
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </div>

                <div className="row">
                  <div className="col-12 text-center">
                    {totalPages && (
                      <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                          {[...Array(totalPages)].map((_, index) => (

                            <li key={index} className={`page-item ${index < currentPage - 2 || index > currentPage + 2 ? "d-none" : ""}`}>
                              <a
                                className={`page-link page-numbers ${index + 1 === currentPage ? "pagination-active" : ""
                                  }`}
                                href={`/${categoria}?page=${index + 1}&order=${order}`}
                                onClick={(e) => {
                                  navigate(`/${categoria}?page=${index + 1}&order=${order}`);
                                  e.preventDefault();
                                  setCurrentPage(index + 1);
                                  document.documentElement.scrollTop = 0;
                                }}
                              >
                                {index + 1}
                              </a>
                            </li>

                          ))}
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>

        </>



      ) : (

        <div>
          <div className="container py-5 my-5">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="fw-bold fs-1 mb-2 text-dark">Cargando</h1>
                <div className="spinner-border color-35 spinner-detail" role="status">
                  <span className="visually-hidden">Cargando...</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      )}



    </>
  );
};

export default BrandPage;
